import React, { useContext } from 'react';
import { Switch, Route, useParams, useRouteMatch, Redirect } from 'react-router-dom';
import AuthContext from '../../../contexts/auth';
import CargaIndividual from './CargaIndividual';
import CargaMasiva from './CargaMasiva';
import DropOff from './DropOff';
import PickUp from './PickUp';
import Buscar from './Search';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io5';
import './index.css';
import { translate } from '../../../utils/Common';

const Paqueteria = () => {
    const userContext = useContext(AuthContext);

    // para saber qué formulario mostrar
    const { page } = useParams();
    const { path } = useRouteMatch();

    const handlePageTitle = (value) => {
        switch (value) {
            case 'loadpackage':
                return <><FaIcons.FaCube size={16} className="mr-2"/>{translate("sidebar.options.packages.loadpackage")}</>;
            case 'load':
                return <><FaIcons.FaCube size={16} className="mr-2"/>{translate("sidebar.options.packages.load")}</>;
            case 'tracking':
                return <><FaIcons.FaSearch size={16} className="mr-2"/>{translate("sidebar.options.packages.tracking")} {translate("global.packages")}</>;
            case 'dropoff':
                return <><IoIcons.IoStorefront size={20} className="mr-2"/>{translate("sidebar.dashboard.packages.dropoff")}</>;
            case 'pickup':
                return <><IoIcons.IoStorefront size={20} className="mr-2"/>{translate("sidebar.dashboard.packages.pickup")}</>;
            default:
                break;
        }
    }

    return  <>
                <div className="dashboardCard text-left">
                    <h5 className='m-0'>{ handlePageTitle(page) }</h5>
                </div>
                
                <>
                    <Switch>
                        <Route path={`${path?.split(':page')[0]}loadpackage`} exact component={CargaIndividual} />
                        <Route path={`${path?.split(':page')[0]}load`} exact component={CargaMasiva} />
                        <Route path={`${path?.split(':page')[0]}tracking`} exact component={Buscar} />
                        { (userContext.user?.IdTipoUsuario === 6 || userContext.user?.IsAdmin) ?
                            <Route path={`${path?.split(':page')[0]}dropoff`} exact component={ DropOff } />
                            :
                            <Redirect exact from='/dashboard/packages/dropoff' to='/dashboard' />
                        }
                        { (userContext.user?.IdTipoUsuario === 6 || userContext.user?.IsAdmin) ?
                            <Route path={`${path?.split(':page')[0]}pickup`} exact component={ PickUp } />
                            :
                            <Redirect exact from='/dashboard/packages/pickup' to='/dashboard' />
                        }
                    </Switch>
                </>
            </>
}

export default Paqueteria;