import * as BiIcons from "react-icons/bi";
import { LuAlertCircle } from "react-icons/lu";
import { FaSignal } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

import {
  statusBusyDoor,
  translate,
  statusOnline,
  statusReservedDoor,
  verifyHideCountry,
} from "../../utils/Common";
import { useState } from "react";
import NumberWithPercentage from "../NumberWithPercentage";

const ItemPlatform = ({ index, item, setSelectedItem }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const handleId = () => {
    if (item.locationId && item.locationId !== "") {
      if (item.zipCode) return `/ ID. ${item.locationId}`;
      else return `ID. ${item.locationId}`;
    } else return "";
  };

  const handleZipCode = () => {
    if (item.zipCode && item.zipCode !== "") {
      if (!verifyHideCountry()) return `CEP: ${item.zipCode}`;
      else return `Zip Code: ${item.zipCode}`;
    } else return "";
  };

  const percentageOcupacity = Number(item.totalDoors) === 0 ? 0 : Math.round(((item.occupiedDoors + item.reservedDoors) / item.totalDoors) * 100);

  const renderHeader = (
    <div
      className="p-3"
      style={{
        borderBottom: "1px solid #d4d4d4",
        background: "#fafafa",
        borderRadius: "0.5rem 0.5rem 0 0",
      }}
    >
      <div className="d-flex justify-content-between">

        <div className="d-flex flex-direction-row justify-content-between">
          <h6 className="font-bold text-left mb-1">
            {item.locationName ?? ""}
          </h6>
          
        </div>

        <div style={{display: "flex", flexDirection: "row", alignItems:"center", gap:6}}>
  
          {item.locationStatus && (
                          <>
                            {item.locationStatus === "ONLINE" ? (
                              <FaSignal color="rgb(74, 222, 128)" size={20} />
                            ) : (
                              <IoMdAlert color="red" size={20} />
                            )}
                          </>
                        )}
          <NumberWithPercentage number={percentageOcupacity} />
        </div>
        </div>
        <p className="mb-1 " style={{ color: "#bcbcbc" }}>
            {handleZipCode()} {handleId()}
          </p>

      
    </div>
  );

  const renderActions = (
    <div className="row p-4">
      <button
        className="btn btn-outline-fucsia col-lg-7 col-md-12 col-sm-7 mr-2 mb-2"
        style={{ fontSize: "12px" }}
        onClick={handleFlip}
      >
        {flipped ? (
          <>
            <BiIcons.BiArrowBack size={15} className="mr-1" />
            {translate("global.return")}
          </>
        ) : (
          <>
            <BiIcons.BiDoorOpen size={15} className="mr-1" />
            {translate("global.availability")}
          </>
        )}
      </button>
        <button
          className="btn btn-fucsia  col-lg-4 col-md-12 col-sm-4 mb-2"
          style={{ fontSize: "12px" }}
          onClick={() => setSelectedItem(item)}
        >
          {translate("global.details")}
        </button>        
    </div>
  );

  const renderContent = !flipped ? (
    <div className="px-3 pt-3" style={{ borderBottom: "1px solid #aca9a9" }}>
      <div className="d-flex justify-content-between">
        <p className="d-flex">
          <span>{statusBusyDoor}</span>
          {translate("global.occupiedDoors")}
        </p>
        <p style={{ color: "#bcbcbc" }}>{item.occupiedDoors ?? "0"}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="d-flex">
          <span>{statusReservedDoor}</span>
          {translate("global.reservedDoors")}
        </p>
        <p style={{ color: "#bcbcbc" }}>{item.reservedDoors ?? "0"}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="d-flex">
          <span>{statusOnline}</span>
          {translate("global.availableDoors")}
        </p>
        <p style={{ color: "#bcbcbc" }}>{item.availableDoors ?? "0"}</p>
      </div>
      <div className="d-flex justify-content-between font-weight-bold">
        <p>{translate("global.totalDoors")}</p>
        <p>{item.totalDoors ?? "0"}</p>
      </div>
    </div>
  ) : (
    <div className="px-3 pt-3" style={{ borderBottom: "1px solid #aca9a9" }}>
      <div className="d-flex justify-content-between">
        <p> {translate("global.smallDoors")}</p>
        <p style={{ color: "#bcbcbc" }}>{item.availableSmallDoors ?? "0"}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p> {translate("global.mediumDoors")}</p>
        <p style={{ color: "#bcbcbc" }}>{item.availableMediumDoors ?? "0"}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p>{translate("global.largeDoors")}</p>
        <p style={{ color: "#bcbcbc" }}>{item.availableLargeDoors ?? "0"}</p>
      </div>
      <div className="d-flex justify-content-between font-weight-bold">
        <p>
          <LuAlertCircle size={15} color="gray" className="mr-1" />
          {translate("global.inactiveDoors")}
        </p>
        <p>{item.inactivedDoors ?? "0"}</p>
      </div>
    </div>
  );

  return (
    <div
      key={index}
      className={`col-md-3 col-sm-6 p-2 ${flipped ? "flipped" : ""}`}
    >
      <div className="border item-platform item-inside text-left">
        {renderHeader}
        {renderContent}
        {renderActions}
      </div>
    </div>
  );
};

export default ItemPlatform;
