import { useState, useEffect, useContext } from 'react';
import AuthContext from '../contexts/auth';
import { usePlatforms } from '../hooks/usePlatforms';
import { translate } from '../utils/Common';
import Spinner from "../components/Spinner";

const PlatformList = ({
    register, 
    errors,
    nameShow,
    idShow,
    readOnly,
    idPlatform,
    handleIsBoxit,
    sizeInput
}) => {
    const userContext = useContext(AuthContext);
    const { platforms, getPlatforms } = usePlatforms();
    const [plataformasList,setPlataformasList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noPlatformsMessage, setNoPlatformsMessage] = useState(null);


    useEffect(() => {
        const abortController = new AbortController();

        const handlePlatforms = async () => {
            setLoading(true);
            const platforms = await getPlatforms(userContext.user?.IdCliente, abortController.signal);
            if (platforms && platforms.length > 0) {
                setPlataformasList(platforms);
            } else {
                setNoPlatformsMessage(translate("global.noPlatforms"));
            }
            setLoading(false);
        }

        if (!platforms) handlePlatforms()
        else setPlataformasList(platforms)
        
        return () => abortController.abort();
    }, [platforms, getPlatforms, userContext.user?.IdCliente]);

    useEffect(() => {
        if (idPlatform !== translate("global.select") && idPlatform !== translate("global.noPlatforms")) {
            const isBoxitSelected = platforms?.filter(p => p.boxitLocationId === Number(idPlatform)) && platforms?.filter(p => p.boxitLocationId === Number(idPlatform))[0]?.IsBoxit;
            if (handleIsBoxit) handleIsBoxit(isBoxitSelected);
        }
    }, [idPlatform, handleIsBoxit, platforms])
    return  (
        <>
             <select
            className={`${sizeInput ?? "form-control-sm"} form-control font-small`}
            readOnly={readOnly}
            name="plataforma"
            ref={register({
                validate: value => value !== (translate("global.select") || translate("components.showItems.results.NOT_PLATFORMS")) || translate("global.chooseValidBoxit")
            })}
        >
            <option defaultValue key={0} className="font-small">
                {nameShow ? nameShow : translate("global.noPlatform")}
            </option>
{plataformasList ? (
            plataformasList?.map(
              (prov) =>
                idShow !== prov.boxitLocationId && (
                  <option
                    key={prov.boxitLocationId}
                    className="font-small"
                    value={prov.boxitLocationId}
                  >{`${prov.locationName}`}</option>
                )
            )
          ) : (
            <option className="font-small">
              {noPlatformsMessage ? noPlatformsMessage : translate("global.noPlatforms")}
            </option>
          )}
        </select>
        {errors.plataforma && <span className="text-error">{errors.plataforma.message}</span>}
        </>
    )
}

export default PlatformList;