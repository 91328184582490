import ItemsInfo from '../../../components/ShowItems/ItemsInfo';
import ShowItems from '../../../components/ShowItems';
import ItemPackage from '../../../components/ShowItems/ItemPackage';
import SearchBoxItems from '../../../components/SearchBoxItems';
import { translate } from '../../../utils/Common';

const PackagesResults = ({
    itemsArray,
    itemsFound,
    itemsShowing,
    setItemsShowing,
    setItemsFiltered,
    setSelectedPackage,
    handleCancelBusqueda
}) => {
    return (
        <>
            <div className="dashboardCard">
                <ItemsInfo
                    cantItemsFound={ itemsFound?.length }
                    itemName={ translate("global.packages") } />

                <SearchBoxItems
                    itemsArray={ itemsArray }
                    itemsFound={ itemsFound }
                    setItemsShowing={ setItemsShowing }
                    setItemsFiltered={ setItemsFiltered }
                    itemName={translate("global.packages")}
                    filterType={"package"}/>
            </div>

            <ShowItems
                ItemComponent={ ItemPackage }
                itemsArray={ itemsArray }
                itemsFound={ itemsFound }
                setItemsFiltered={ setItemsFiltered }
                itemName={translate("global.packages")}
                view='grid'
                showBox={ true }
                itemsShowing={itemsShowing}
                handleCancelBusqueda={ handleCancelBusqueda }
                setSelectedItem={ setSelectedPackage } />
        </>
    );
}

export default PackagesResults;