import {  useState, useContext } from "react";
import ModalContext from "../../../../../contexts/modal";
import { usePackages } from "../../../../../hooks/usePackages";
import Spinner from '../../../../../components/Spinner';
import { translate } from "../../../../../utils/Common";

import './index.css'

const SearchPackage = ({ setPackageInfo }) => {
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [typeSearch, setTypeSearch] = useState(false);
  const modalContext = useContext(ModalContext);


  const { getPackages } = usePackages();
  const handleSearch = async () => {
    try {
      setError('')
      isloading(true);
      const response = await getPackages({value: search, type: typeSearch});
      if(response.length > 0) setPackageInfo(response[0]);
      else setError('No se ha encontrado ningun paquete');
      isloading(false);
    } catch (error) {
      console.log(error);
      isloading(false);

      ModalContext.confirm(translate("global.alerts.ops"), 
      <span>{translate("dashboard.platforms.details.error")}</span>,
      <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => ModalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
      true);
    }
  };

  const isloading = (value) => {
    if(value) modalContext.confirm(
        translate("global.loading"), 
        <div className="p-3 text-center">
            <Spinner type={1} size={15}/>
        </div>,
        null,
        true);
     else modalContext.setIsOpenModal(false);
}

  const handleClearInput = () => {
    setPackageInfo('');
    setSearch('');
    setError('');
  }

  return (
    <div className="dashboardCard mb-2">
      <h6 className="mb-4 text-center">{translate("dashboard.platforms.support.search.select")}</h6>

      <div className="row d-flex justify-content-center">
        <div className="col-6 col-md-4 col-lg-3 col-xl-2 ml-3 ml-lg-0 text-left">
        <div className="custom-control custom-switch ">
                  <input className="custom-control-input form-control" checked={!typeSearch ? true : false} onChange={(e) => setTypeSearch(!typeSearch)}  type="checkbox" id="clave"/>
                  <label htmlFor="clave" className="custom-control-label">
              <div className="mt-1">{translate("global.key")}</div>
            </label>
                  </div>

          <div className="custom-control custom-switch">
            <input
              className="custom-control-input"
              checked={ typeSearch ? true : false } 
              onChange={(e) => setTypeSearch(!typeSearch)} 
              type="checkbox"
              id="numeroPaquete"
              
            />
            <label htmlFor="numeroPaquete" className="custom-control-label">
              <div className="mt-1">{translate("global.numPackage")}</div>
            </label>
          </div>
        </div>
        <div className="col-12 col-md-6 mt-4 mt-md-2">
          <div className="col-12 col-lg-9 p-0">
            <div className="input-group p-0">
              <input
                type="text"
                className={
                  "form-control form-control-md font-small" +
                  (error ? " mb-2 is-invalid" : "")
                }
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                placeholder={translate("global.search")}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                nombre="inputText"
              />
              <div className="input-group-append">
              <button
                className="btn btn-sm font-small btn-blue"
                type="button"
                id="inputGroupFileAddon04"
                onClick={() => handleSearch()}
                disabled={ search === '' }
              >
                {translate("global.search")}
              </button>
              <button
                className="btn btn-sm font-small btn-outline-blue"
                type="button"
                id="inputGroupFileAddon04"
                onClick={() => handleClearInput()}
              >
                {translate("global.clean")}
              </button>
              </div>
              {error && 
              <span className="text-danger pt-1">{error}</span>
              }
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPackage;
