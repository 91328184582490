import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { getClDomain, translate } from '../../utils/Common';
// contexts
import AuthContext from '../../contexts/auth';
import SidebarContext from '../../contexts/sidebar';
// icons
import * as BsIcons from 'react-icons/bs';
import './index.css';
// sidebar info
import Submenu from './Submenu';
import Options from './Options/indexRoles';

const Sidebar = () => {
	const userContext = useContext(AuthContext);
	const sidebarContext = useContext(SidebarContext);
	const [SidebarData,] = useState(Options(userContext.user));

	const handleName = () => {
		if (userContext.user?.NombreSimple.indexOf(' ') > -1) return `${userContext.user?.NombreSimple.split(' ')[0]} ${userContext.user?.ApellidoSimple}`;
		else return `${userContext.user?.NombreSimple} ${userContext.user?.ApellidoSimple}`;
	}

	const handlePerfilOption = () => {
		sidebarContext.handleChangeCurrentPage('Perfil');

		if (sidebarContext.isMobile) sidebarContext.closeSidebar();
	}

	return (
		<div 
			className={ "sidebar" + (sidebarContext.sidebarOpen ? "-open" : "-close") }
			style={ getClDomain() === 'bli.myboxit.com' ? { top: '0' } : { top: '22px' } }>
			<div className="logo-section d-flex justify-content-between">
				<div className="logo-boxit" style={{
					backgroundImage: sidebarContext.sidebarOpen ? `url(/img/logo-boxit.png)` : "url(/img/logo-b.png)",
					width: sidebarContext.sidebarOpen ? 86 : 35,
					height: sidebarContext.sidebarOpen ? 33 : 35
				 }} />
				{ sidebarContext.isMobile && 
					<div className="close-icon mt-1 mr-3" onClick={ () => sidebarContext.closeSidebar() } >
						<BsIcons.BsX className="btn-close" size={ 30 }/>
					</div>
				}
			</div>

			<div className="usersection d-flex flex-column mb-3 pb-4">
				<div className="usersection-icon mt-4 mx-auto">
					<Link to="/dashboard/profile/" className="user-icon" onClick={ () => handlePerfilOption() }>
						<BsIcons.BsPersonCircle className={ 'user-icon ' + (sidebarContext.currentPage === 'Perfil' ? 'boxit-fucsia' : 'boxit-gray') } size={ sidebarContext.sidebarOpen ? 65 : 45 } />
					</Link>
				</div>

				<div className={ "text-left mt-3 ml-4 userdata" + (!sidebarContext.isMobile && (sidebarContext.sidebarOpen ? '' : '-close')) }>
					<div className="mb-2">
						<h6 className="sidebar-title mb-0">{translate("sidebar.user")}:</h6>
						<span className="sidebar-data">{ userContext.user?.Usuario ? userContext.user?.Usuario : 'USUARIO' }</span>
					</div>
					
					<div>
						<h6 className="sidebar-title mb-0">{translate("sidebar.name")}:</h6>
						<span className="sidebar-data">{ userContext.user?.NombreSimple ? handleName() : 'Usuario' }</span>
					</div>
				</div>
			</div>

			<ul className="sidebarWrap d-flex flex-column">
			{
				SidebarData && SidebarData.map((item, index) => {
					return <li key={index} className="relative">
								<Submenu item={item} />
							</li>
				})
			}
			</ul>
		</div>
	);
}

export default Sidebar;