import React, { useEffect, useState, useContext } from "react";
import XLSX from "xlsx";
import { getClientDomain, getUser, getToken, round, translate, parseExcel, formatFileSize, formatFileType } from "../../../../utils/Common";
import { useForm } from "react-hook-form";
import Spinner from "../../../../components/Spinner";
import AuthContext from "../../../../contexts/auth";
import ModalContext from "../../../../contexts/modal";
import SearchClient from "../CargaIndividual/SearchClient";
import "./index.css";
import ProviderList from "../../../../components/ProviderList";
import { SiMicrosoftexcel } from "react-icons/si";
import TipoPaqueteSwitch from "../TipoPaqueteSwitch";



// componente para hacer la carga masiva
const CargaMasiva = (props) => {
  const userContext = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  const country = localStorage.getItem("country");
  const { register, errors, setError, clearErrors, setValue } = useForm();

  // variables from excel
  const [file, setFile] = useState(null);
  const [excelTable, setExcelTable] = useState(null);
  const [resultTable, setResultTable] = useState(null);
  // const [excelData, setExcelData] = useState(null);
  const [providor, setProvidor] = useState(null);
  const [idTipoPaquete, setIdTipoPaquete] = useState(2); 

  // show message about error
  const [isErrorUploading, setIsErrorUploading] = useState(false);
  let fileError = false;

  // tipo de usuario que se le va a realizar la carga
  const [idTipoUsuarioCarga, setIdTipoUsuarioCarga] = useState(
    userContext.user?.IsAdmin ? null : userContext.user?.IdTipoUsuario
  );

  const [uploadImage, setUploadImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dataImage, setDataImage] = useState({ name: "", size: "", type: "" });

  // read file excel and render
  const handleFileUpload = (fileObj) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      const resp = parseExcel(sheetData);

      let newRows = [];
      let newCols = [];

      if (fileError) {
        setError('file', {
          type: '',
          message: translate("dashboard.packages.load.uploadFile.fileDistintic")
        });
        return;
      } else {
        if (!validateFile(resp)) {
          setError('file', {
            type: '',
            message: translate("dashboard.packages.load.uploadFile.fileDistintic")
          });
          return;
        }
      }

      resp.rows.map((row, index) => {
        if (row && row.length > 0) {
          if (index === 0) {
            newCols.push('#');
            if (userContext.user?.IsAdmin) newCols.push('USUARIO');
            getColumnOrder().forEach(col => newCols.push(col?.NombreCampoExcel.toUpperCase()));
            if (idTipoUsuarioCarga === 1) newCols.push('COSTOTOTAL');
          }
          else {
            switch (idTipoUsuarioCarga) {
              case 1:
                let data = {
                  usuario: userContext.user?.IsAdmin ? Number(selectedUser.IdUsuario) : userContext.user?.Ruc,
                  cliente: sheetData[index - 1]?.CLIENT ?? 'SIN CLIENTE',
                  tracking: sheetData[index - 1]?.TRACKING ?? 'SIN TRACKING',
                  pesoReal: sheetData[index - 1]["ACTUAL WEIGHT"] ?? 0,
                  pesoVol: sheetData[index - 1]["VOL WEIGHT"] ?? 0,
                  alto: sheetData[index - 1]?.HEIGHT ?? 0,
                  largo: sheetData[index - 1]?.LENGTH ?? 0,
                  ancho: sheetData[index - 1]?.WIDTH ?? 0,
                  paquete: sheetData[index - 1]?.PACKAGE ?? 'SIN NÚMERO',
                  plataforma: sheetData[index - 1]?.BOXIT ?? 'SIN PLATAFORMA',
                  precioLibra: round(Number(sheetData[index - 1]["POUND PRICE"]), 2) ?? 0,
                  costoProv: sheetData[index - 1]["COSTO PROV"] ?? 0
                }

                // validar qué peso se va a tomar
                if (data.pesoVol > 15) {
                  if (data.pesoVol > data.pesoReal) data.peso = data.pesoVol;
                  else data.peso = data.pesoReal;
                }
                else {
                  data.peso = data.pesoReal;
                }

                data.costoProv = round(data.peso * data.precioLibra, 2);

                newRows.push(data);
                // newRows.push(row);
                break;

              case 2:
                newRows.push({
                  usuario: userContext.user?.IsAdmin ? Number(selectedUser.IdUsuario) : userContext.user?.Ruc,
                  cliente: userContext.user?.IsAdmin ? selectedClient?.IdCliente : userContext.user?.IdCliente,
                  paquete: sheetData[index - 1]?.PACKAGE ?? 'SIN NÚMERO',
                  tracking: sheetData[index - 1]?.TRACKING ?? 'SIN TRACKING',
                  plataforma: sheetData[index - 1]?.BOXIT ?? 'SIN PLATAFORMA',
                  email: sheetData[index - 1]?.EMAIL ?? 'SIN EMAIL',
                  estatus: 'NUEVO',
                  montoXPagar: sheetData[index - 1]?.AMOUNTPAY ?? 0,
                  alto: sheetData[index - 1]?.HEIGHT ?? 0,
                  largo: sheetData[index - 1]?.LENGTH ?? 0,
                  ancho: sheetData[index - 1]?.WIDTH ?? 0,
                });
                break;

              case 3:
                newRows.push({
                  usuario: userContext.user?.IsAdmin ? Number(selectedUser.IdUsuario) : userContext.user?.Ruc,
                  cliente: userContext.user?.IsAdmin ? selectedClient?.IdCliente : userContext.user?.IdCliente,
                  // paquete: sheetData[index - 1]?.PAQUETE ?? 'SIN NÚMERO',
                  tracking: sheetData[index - 1]?.TRACKING ?? 'SIN TRACKING',
                  plataforma: sheetData[index - 1]?.BOXIT ?? 'SIN PLATAFORMA',
                  email: sheetData[index - 1]?.EMAIL ?? 'SIN EMAIL',
                  estatus: 'NUEVO',
                  montoXPagar: sheetData[index - 1]?.AMOUNTPAY ?? 0,
                  tamano: sheetData[index - 1]?.SIZE ?? 'SIN TAMAÑO',
                  descripcion: sheetData[index - 1]?.DESCRIPTION ?? 'SIN DESCRIPCIÓN'
                });
                break;

              default:
                break;
            }
          }
        }
        return false;
      });

      if (newRows.length === 0) {
        setError('file', {
          type: '',
          message: translate("dashboard.packages.load.uploadFile.noData")
        });
      }
      else {
        setFile({ cols: newCols, rows: newRows });
        setExcelTable(validatePackage({ package: { cols: newCols, rows: newRows } }));
      }
    };
    reader.readAsBinaryString(fileObj);
  };

  // upload file and show it on screen
  const uploadFile = (e) => {
    try {
      setIsErrorUploading(false);
      fileError = false;

      if (userContext.user?.IsAdmin && !selectedUser) {
        setError("selectCompany", {
          type: "",
          message: translate(
            "dashboard.packages.load.uploadFile.selectCompany"
          ),
        });
        fileError = true;
      }

      let fileObj = e.target.files[0];

      if (!fileObj) {
        setError("file", {
          type: "",
          message: translate("dashboard.packages.load.uploadFile.fileObj"),
        });
        return;
      }

      if (
        !(
          fileObj.type === "application/vnd.ms-excel" ||
          fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        setError("file", {
          type: "",
          message: translate("dashboard.packages.load.uploadFile.file"),
        });
        return;
      }

      if (
        userContext.user?.IsAdmin &&
        idTipoUsuarioCarga > 1 &&
        !selectedClient?.IdCliente
      ) {
        setError("file", {
          type: "",
          message: translate(
            "dashboard.packages.load.uploadFile.selectedClient"
          ),
        });
        return;
      }

      handleFileUpload(fileObj);

    } catch (error) {
      console.log(error);
      setError("file", {
        type: "",
        message: translate("dashboard.packages.load.uploadFile.errorUpload"),
      });
    }
  };

  const [isSendingData, setIsSendingData] = useState(false);
  // send data to server
  const registerData = async () => {
    try {
      setIsSendingData(true);
      loading(true);

      let data = { packages: file.rows };
      data.idTipoUsuario = idTipoUsuarioCarga;
      if (!userContext.user?.IsAdmin) {
        data.proveedor = userContext.user?.Ruc;
        data.cliente = userContext.user?.IdCliente;
      } else {
        data.proveedor = selectedUser.IdUsuario;
        if (selectedUser.IdTipoUsuario > 1)
          data.cliente = selectedClient.IdCliente;
        else data.cliente = 0;
      }

      data.idtipoPaquete = idTipoPaquete;

      const controller = new AbortController();
      const response = await fetch(
        `${getClientDomain(2)}/dashboard/packages/register/masiva`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
          signal: controller.signal,
        }
      );

      const result = await response.json();
      if (result) {
        if (result.code === 200 || result.code === 400) {
          if (result.message === "PACKAGES_LOADED") {
            // show message packages register successfully
            setFile(null);
            resetForm();
            modalContext.confirm(
              translate("dashboard.packages.load.uploadFile.modal.info"),
              translate("dashboard.packages.load.uploadFile.modal.success"),
              <button
                type="button"
                className="btn btn-sm btn-fucsia"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.accept")}
              </button>,
              true
            );
            setIsErrorUploading(false);
          } else if (result.message === "SOME_PACKAGES_NOT_LOADED") {
            var newCols = file.cols;
            newCols.shift("#");
            newCols.unshift("#", "MENSAJE");
            setExcelTable(null);
            setResultTable(
              validatePackage(
                { package: { cols: newCols, rows: result.packagesList } },
                true
              )
            );
            modalContext.confirm(
              translate("dashboard.packages.load.uploadFile.modal.attention"),
              translate("dashboard.packages.load.uploadFile.modal.noSuccess"),
              <button
                type="button"
                className="btn btn-sm btn-fucsia"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.accept")}
              </button>,
              true
            );
          }
        } else {
          resetForm();
          setFile(null);
          modalContext.confirm(
            translate("global.alerts.ops"),
            translate("dashboard.packages.load.uploadFile.modal.error"),
            <button
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => modalContext.setIsOpenModal(false)}
            >
              {translate("global.accept")}
            </button>,
            true
          );
        }
      } else {
        resetForm();
        setFile(null);
        modalContext.confirm(
          translate("global.alerts.ops"),
          translate("dashboard.packages.load.uploadFile.modal.error"),
          <button
            type="button"
            className="btn btn-sm btn-fucsia"
            onClick={() => modalContext.setIsOpenModal(false)}
          >
            {translate("global.accept")}
          </button>,
          true
        );
      }
    } catch (error) {
      console.log(error);
      resetForm();
      setFile(null);
      modalContext.confirm(
        translate("global.alerts.ops"),
        translate("dashboard.packages.load.uploadFile.modal.error"),
        <button
          type="button"
          className="btn btn-sm btn-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.accept")}
        </button>,
        true
      );
    } finally {
      setIsSendingData(false);
    }
  };

  // get column order
  const getColumnOrder = (col) => {
    let columnas = [];

    if (idTipoUsuarioCarga) {
      switch (idTipoUsuarioCarga) {
        case 1:
          columnas = supplierLoad; break;
        case 2:
          columnas = B2BLoad; break;
        case 3:
          columnas = entrepreneurLoad; break;
        default:
          break;
      }
    }

    return columnas;
  }

  // validar que el archivo sea el correcto
  const validateFile = (file) => {
    let columnas = file.rows[0];

    if (idTipoUsuarioCarga) {
      if (idTipoUsuarioCarga === 1) {
        if (equals(columnas, supplierLoad)) return true;
      } else if (idTipoUsuarioCarga === 2) {
        if (equals(columnas, B2BLoad)) return true;
      } else if (idTipoUsuarioCarga === 3) {
        if (equals(columnas, entrepreneurLoad)) return true;
      }
    }

    return false;
  };
  // metodo para validar si el registro del paquete está correcto (fila del excel)
  const validatePackage = (data, result) => {
    let tableHeader = (
      <thead>
        <tr>
          {data.package.cols &&
            data.package.cols.map((col, index) => {
              return (
                <th className="text-center" key={index}>
                  {col}
                </th>
              );
            })}
        </tr>
      </thead>
    );

    let tableRows = [];
    let isErrorUploadingAux = false;
    tableRows.push(
      data.package.rows.map((row, index) => {
        let packageError,
          clienteError,
          trackingError,
          emailError,
          sizeError,
          precioLibraError = false;
        let plataformaError = true;

        if (!result) {
          // transformar medidas a cm
          // if (row.paquete.alto) row.paquete.alto = round(Number(row.paquete.alto) * 2.54, 2);
          // if (row.paquete.largo) row.paquete.largo = round(Number(row.paquete.largo) * 2.54, 2);
          // if (row.paquete.ancho) row.paquete.ancho = round(Number(row.paquete.ancho) * 2.54, 2);

          // validar numero de paquete
          if (idTipoUsuarioCarga !== 3) {
            if (row.paquete.toUpperCase() === "SIN NÚMERO") packageError = true;
          }

          // validar tracking
          if (row.tracking.toUpperCase() === "SIN TRACKING")
            trackingError = true;

          if (idTipoUsuarioCarga !== 1) {
            // validar plataforma
            if (avaliblePlatforms) {
              avaliblePlatforms.forEach((boxit) => {
                if (
                  boxit?.locationName.toUpperCase().includes(
                    row.plataforma.toUpperCase()
                  ) !== false
                ) {
                  plataformaError = false;
                  row.plataforma = boxit.locationName;
                }
                return;
              });
            }

            // validar email
            if (
              row.email.toUpperCase() === "SIN EMAIL" ||
              row.email.toUpperCase().indexOf("@") === -1
            )
              emailError = true;

            // validar tamano
            if (idTipoUsuarioCarga === 3) {
              switch (row.tamano.toUpperCase()) {
                case "P":
                  break;
                case "M":
                  break;
                case "G":
                  break;
                default:
                  sizeError = true;
                  break;
              }
            }
          } else {
            // validar si introdujo # cliente
            if (idTipoUsuarioCarga === 1)
              if (row.cliente.toUpperCase() === "SIN CLIENTE")
                clienteError = true;

            // validar si introdujo # cliente
            if (idTipoUsuarioCarga === 1 && !userContext.user?.IsAdmin)
              if (row.precioLibra === 0) precioLibraError = true;

            // no valido si se introdujo el nombre de las plataformas
            plataformaError = false;
            if (row.plataforma === "") row.plataforma = "SIN PLATAFORMA";
          }
        } else {
          plataformaError = false;
          // validar cual fue el resultado
          switch (row.resultado) {
            case "El número de paquete ya se encuentra registrado.":
              packageError = true;
              break;
            case "El número de tracking ya se encuentra registrado.":
              trackingError = true;
              break;
            case "El ID del cliente no existe.":
              clienteError = true;
              break;
            case "No tienes autorización para entregar en este Boxit.":
              plataformaError = true;
              break;
            default:
              break;
          }
        }

        if (
          packageError ||
          trackingError ||
          plataformaError ||
          emailError ||
          clienteError ||
          sizeError ||
          precioLibraError
        )
          isErrorUploadingAux = true;
        else {
          if (isErrorUploadingAux !== true) {
            isErrorUploadingAux = false;
          }
        }

        switch (idTipoUsuarioCarga) {
          case 1:
            return (
              <tr
                className={
                  packageError ||
                    trackingError ||
                    plataformaError ||
                    clienteError ||
                    precioLibraError
                    ? "bg-warning"
                    : ""
                }
                key={index}
              >
                <th>{index + 1}</th>
                {result && (
                  <td className="text-bold text-error">{row.resultado}</td>
                )}
                {userContext.user?.IsAdmin && <td>{row.usuario}</td>}
                <td className={clienteError ? "text-bold text-error" : ""}>
                  {row.cliente}
                </td>
                <td className={trackingError ? "text-bold text-error" : ""}>
                  {row.tracking}
                </td>
                <td>{row.pesoReal}</td>
                <td>{row.pesoVol}</td>
                <td>{row.alto}</td>
                <td>{row.largo}</td>
                <td>{row.ancho}</td>
                <td className={packageError ? "text-bold text-error" : ""}>
                  {row.paquete}
                </td>
                <td className={plataformaError ? "text-bold text-error" : ""}>
                  {row.plataforma}
                </td>
                <td className={precioLibraError ? "text-bold text-error" : ""}>
                  {row.precioLibra}
                </td>
                <td>{row.costoProv}</td>
              </tr>
            );

          case 2:
            return (
              <tr
                className={
                  packageError ||
                    trackingError ||
                    plataformaError ||
                    emailError ||
                    sizeError
                    ? "bg-warning"
                    : ""
                }
                key={index}
              >
                <th>{index + 1}</th>
                {result && (
                  <td className="text-bold text-error">{row.resultado}</td>
                )}
                {userContext.user?.IsAdmin && <td>{row.usuario}</td>}
                <td className={packageError ? "text-bold text-error" : ""}>
                  {row.paquete}
                </td>
                <td className={trackingError ? "text-bold text-error" : ""}>
                  {row.tracking}
                </td>
                <td className={plataformaError ? "text-bold text-error" : ""}>
                  {row.plataforma}
                </td>
                <td className={emailError ? "text-bold text-error" : ""}>
                  {row.email}
                </td>
                <td>{row.montoXPagar}</td>
                <td>{row.alto}</td>
                <td>{row.largo}</td>
                <td>{row.ancho}</td>
              </tr>
            );

          case 3:
            return (
              <tr
                className={
                  packageError ||
                    trackingError ||
                    plataformaError ||
                    emailError ||
                    sizeError
                    ? "bg-warning"
                    : ""
                }
                key={index}
              >
                <th>{index + 1}</th>
                {result && (
                  <td className="text-bold text-error">{row.resultado}</td>
                )}
                {userContext.user?.IsAdmin && <td>{row.usuario}</td>}
                {/* <td className={packageError ? "text-bold text-error" : ""}>
                  {row.paquete}
                </td> */}
                <td className={trackingError ? "text-bold text-error" : ""}>
                  {row.tracking}
                </td>
                <td className={plataformaError ? "text-bold text-error" : ""}>
                  {row.plataforma}
                </td>
                <td className={emailError ? "text-bold text-error" : ""}>
                  {row.email}
                </td>
                <td>{row.montoXPagar}</td>
                <td className={sizeError ? "text-bold text-error" : ""}>
                  {row.tamano}
                </td>
                <td>{row.descripcion}</td>
              </tr>
            );

          default:
            break;
        }

        return false;
      })
    );
    if (isErrorUploadingAux) setIsErrorUploading(true);
    else setIsErrorUploading(false);

    return (
      <div className="row table-excel-file mx-0">
        <div className="text-left col-12 mb-1 px-0">
          {translate("dashboard.packages.load.uploadFile.infoPackage")}
        </div>

        <div className="table-responsive mt-2 mb-0 px-0">
          <table className="table table-striped table-bordered excel-file table-hover table-sm">
            {tableHeader}
            <tbody>
              {data.package.rows !== "No hay registros en el archivo" &&
                tableRows}
            </tbody>
            {tableHeader}
          </table>
        </div>
      </div>
    );
  };

  // funcion para comparar dos arreglos
  const equals = (a, b) => {
    return a.length === b.length &&
      b.every(element =>
        a.includes(element.NombreCampoExcel) ? true : false
      );
  }

  // ordernar la posicion de los fields del excel format que vienen del server
  const ordenarServerExcelFields = (datos) => {
    for (let i = 0; i < datos.length - 1; i++) {
      for (let f = 0; f < datos.length - i - 1; f++) {
        if (datos[f].OrderFields > datos[f + 1].OrderFields) {
          let aux = datos[f];
          datos[f] = datos[f + 1];
          datos[f + 1] = aux;
        }
      }
    }

    return datos;
  };

  // reiniciar formulario y cancelar carga de paquetes
  const resetForm = () => {
    setFile(null);
    setResultTable(null);
    setExcelTable(null);
    clearErrors();
    setIsErrorUploading(false);
    setBuscarCliente(null);
    setSelectClientes(null);
    setSelectedClient(null);
    setSelectedUser(null);
  };

  // para obtener los proveedores
  const [selectedUser, setSelectedUser] = useState(null);
  // handle change of company name

  const loading = () => {
    modalContext.confirm(
      "Cargando...",
      <div className="p-3 text-center">
        <Spinner type={1} size={15} />
      </div>,
      <button
        type="button"
        className="btn btn-sm btn-fucsia"
        onClick={() => modalContext.setIsOpenModal(false)}
      >
        {translate("global.accept")}
      </button>,
      true
    );
  };

  const getExcelFile = async () => {
    try {
      if (idTipoUsuarioCarga) {
        const response = await fetch(
          `${getClientDomain()}/files/xlsx/${idTipoUsuarioCarga}`,
          {
            method: "GET",
            accept: "application/json",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        const result = await response.json();
        if (result) {
          if (result.code === 200) {
            let wb = XLSX.utils.book_new(); //create new
            let ws = XLSX.utils.aoa_to_sheet(result.payload[0].fileModel); // crea una hoja de excel
            ws["!cols"] = result.payload[0].colProps; // nombre de las columnas
            XLSX.utils.book_append_sheet(wb, ws, result.payload[0].sheetName); //la hoja la mete dentro del libro
            XLSX.writeFile(wb, `${result.payload[0].fileName}.xlsx`); //crea el archivo
          } else {
            setError("file", {
              type: "",
              message: translate(
                "dashboard.packages.load.uploadFile.fileNotAvailable"
              ),
            });
          }
        } else {
          setError("file", {
            type: "",
            message: translate(
              "dashboard.packages.load.uploadFile.fileNotAvailable"
            ),
          });
        }
      } else
        setError("file", {
          type: "",
          message: translate("dashboard.packages.load.uploadFile.selectedUser"),
        });
    } catch (error) {
      console.log("error:", error);
    }
  };

  const getFileModel = async (value) => {
    try {
      if (value) {
        const response = await fetch(
          `${getClientDomain(2)}/dashboard/packages/file/${value}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${getToken()}`,
            },
          }
        );

        const result = await response.json();
        if (result) {
          if (result.payload) {
            if (result.payload.length > 0) {
              let campos = [];
              result.payload.map((col) => {
                campos.push(col);
                return false;
              });

              switch (value) {
                case 1:
                  setSupplierLoad(ordenarServerExcelFields(campos));
                  break;
                case 2:
                  setB2BLoad(ordenarServerExcelFields(campos));
                  break;
                case 3:
                  setEntrepreneurLoad(ordenarServerExcelFields(campos));
                  break;

                default:
                  break;
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // variables usadas para la busqueda del cliente
  const [buscarCliente, setBuscarCliente] = useState(null);
  const [selectClientes, setSelectClientes] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const handleSelectedClient = (value) => {
    if(selectClientes){
      const client = selectClientes.filter(
        (client) => client.IdCliente === Number(value)
      )[0];
      setSelectedClient(client ? client : null);
    }
  };

  // Get plataformas disponibles from server
  const [avaliblePlatforms, setAvaliblePlatforms] = useState([]);
  // para obtener el formato del archivo excel
  const [entrepreneurLoad, setEntrepreneurLoad] = useState([]);
  const [supplierLoad, setSupplierLoad] = useState([]);
  const [B2BLoad, setB2BLoad] = useState([]);

  // obtener las plataformas disponibles y el modelo del archivo excel
  useEffect(() => {
    const user = getUser();
    const token = getToken();
    const clientDomain = getClientDomain(2);
    const controller = new AbortController();

    const getFileInfo = async () => {
      if (sessionStorage.getItem("platforms")) {
        setAvaliblePlatforms(JSON.parse(sessionStorage.getItem("platforms")));
      } else {
        try {
          const response = await fetch(
            `${clientDomain}/dashboard/platforms/client`,
            {
              method: "GET",
              accept: "application/json",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
              signal: controller.signal,
            }
          );

          const result = await response.json();
          if (result) {
            if (result.payload) {
              if (result.payload.length > 0) {
                setAvaliblePlatforms(result.payload);
                sessionStorage.setItem(
                  "platforms",
                  JSON.stringify(result.payload)
                );
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      }

      if (!user?.IsAdmin) {
        try {
          const response = await fetch(
            `${getClientDomain(2)}/dashboard/packages/file/${idTipoUsuarioCarga}`,
            {
              method: "GET",
              accept: "application/json",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
              signal: controller.signal,
            }
          );

          const result = await response.json();
          
          if (result) {
            if (result.payload) {
              if (result.payload.length > 0) {
                let campos = [];
                result.payload.map((col) => {
                  campos.push(col);
                  return false;
                });
                switch (idTipoUsuarioCarga) {
                  case 1:
                    setSupplierLoad(ordenarServerExcelFields(campos));
                    break;
                  case 2:
                    setB2BLoad(ordenarServerExcelFields(campos));
                    break;
                  case 3:
                    setEntrepreneurLoad(ordenarServerExcelFields(campos));
                    break;

                  default:
                    break;
                }
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    };

    getFileInfo();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (providor) {
      resetForm();
      setSelectedUser(providor ? providor : null);
      setIdTipoUsuarioCarga(providor ? providor.IdTipoUsuario : null);
      getFileModel(providor ? providor.IdTipoUsuario : null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providor]);
  return (
    <form className="dashboardCard carga-masiva">
      <TipoPaqueteSwitch idTipoPaquete={idTipoPaquete} setIdTipoPaquete={setIdTipoPaquete} />
      {userContext.user?.IsAdmin && !file && (
        <>
          <div className="form-row mx-0 text-left pt-4">
            <div className="col-12">
              <p className="p-0 m-0">
                {translate("dashboard.packages.load.uploadFile.title")}:
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 mb-0 px-1 text-left">
                        <label htmlFor="selectProveedor" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("global.business")}:</label>
                        <ProviderList 
                            register={register}
                            nameShow={translate("global.select")}
                            idShow={"all"}
                            idHide={country==='br' ? true : false}
                            readOnly={false}
                            setValue={setValue}
                            valueId={"proveedor"}
                            setProveedor={setProvidor}/>
                    </div>
        </>
      )}

      {selectedUser?.IdTipoUsuario > 1 && !file && (
        <div className="mx-0 mt-0 mb-2 p-0 text-left">
          <SearchClient
            idUsuarioSelected={selectedUser?.IdUsuario}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            register={register}
            idTipoCliente={ selectedUser.IdTipoUsuario > 1 ? 7 : 1 }
            buscarCliente={buscarCliente}
            handleBuscarCliente={setBuscarCliente}
            selectClientes={selectClientes}
            handleSelectClientes={setSelectClientes}
            handleSelectedClient={handleSelectedClient}
          />
        </div>
      )}


<div className="col-lg-6 col-sm-12 mt-3">


          {!file && (
            <>
            <div className="col-sm-12 text-left mb-1">
            <span>
              {translate("dashboard.packages.load.uploadFile.selectedExcel")}:
            </span>
          </div>
            <label
              htmlFor="inputFileName"
              style={{ cursor: "pointer" }}
              className="input-click-image-billing"
            >
              <SiMicrosoftexcel size={20} style={{ marginRight: "5px" }} /> {translate("global.attachFileExcel")} <span className="red-label">*</span>
              {errors.datePayment && (
                <p className="error-message">{errors.datePayment.message}</p>
              )}
            </label>

            <input
            type="file"
            onChange={uploadFile}
              id="inputFileName"
              accept=".xlsx"
              name="file"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              opacity: "0",
            }}
            ref={register}
          />

            <div className="col-sm-9 text-left">
            <br />
            {errors.file && (
              <span className="text-error">{errors.file.message}</span>
            )}
          </div>

        <div className="col-sm-9 text-left font-xsmall">
            <p className="m-0 p-0">
              {translate("dashboard.packages.load.uploadFile.lostModel")}{" "}
              <span
                className="link font-xsmall "
                onClick={() => getExcelFile()}
              >
                {translate("dashboard.packages.load.uploadFile.download")}
              </span>
            </p>
          </div>
            </>
            
          )}
         

        </div>

      {file && (
        <>
          {excelTable && excelTable}
          {resultTable && resultTable}
        </>
      )}

      {isErrorUploading && (
        <div className="form-row col-sm-12 m-2">
          <span className="text-error">
            {translate("dashboard.packages.load.uploadFile.errorLoadPackage")}
          </span>
        </div>
      )}

      <div className="form-row col-md-12 d-flex justify-content-center mt-2">
        {file && excelTable && (
          <div>
            <button
              className="btn btn-outline-fucsia m-2"
              type="reset"
              onClick={() => resetForm()}
            >
              {isErrorUploading
                ? translate("global.clean")
                : translate("global.cancel")}
            </button>
            {!isErrorUploading && (
              <button
                className="btn btn-fucsia m-2"
                type="button"
                disabled={isSendingData}
                onClick={() => registerData()}
              >
                {translate("global.register")}
              </button>
            )}
          </div>
        )}
        {file && resultTable && (
          <div>
            <button
              className="btn btn-outline-fucsia m-2"
              type="reset"
              onClick={() => resetForm()}
            >
              {translate("global.clean")}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default CargaMasiva;
