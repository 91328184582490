import React, { useContext } from 'react';
import AuthContext from '../../../contexts/auth';
import './index.css';

// components
import AdminHome from './AdminStats';
import UserHome from './UserStats';
import CounterHome from './CounterHome';
import { translate } from '../../../utils/Common';

// vista de home insights
const Home = () => {
    const UserContext = useContext(AuthContext);

    const getName = () => {
        if (UserContext.user.NombreSimple.indexOf(' ')) {
            return UserContext.user.NombreSimple.split(' ')[0];
        }
        else {
            return UserContext.user.NombreSimple;            
        }
    }

    const greetings = () => {
        const hour = new Date().getHours();
        if (hour >= 0 && hour < 12){
            return translate("dashboard.home.greeting.morning");
        }
        else if (hour >= 12 && hour < 19) {
            return translate("dashboard.home.greeting.afternoon");
        }
        else if (hour >= 19) {
            return translate("dashboard.home.greeting.evening");
        }
    }

    return (
        <>
            <div className="dashboardCard text-left mb-2">
                <h4 className="m-0">{ greetings() + (UserContext.user ? getName() : 'Usuario') }!</h4>
                <p className="m-0">{translate("dashboard.home.welcome")} <span className="text-bold">{translate("dashboard.home.team")}</span></p>
            </div>

            { UserContext.user?.IsAdmin ?
                <AdminHome />
                :
                ( UserContext.user?.IdTipoUsuario === 6 ?
                    <CounterHome />
                    :
                    <UserHome />
                )
            }
        </>
    );
}

export default Home;