import { scrollTo, setPage, translate } from '../../utils/Common';
// icons
import * as IoIcons from "react-icons/io";
import { useLocation } from "react-router-dom";

const ItemsPagination = ({ actualPage, setActualPage, pages, itemsFound, exportData, itemName, handleCancelBusqueda }) => {
    const handlePagination = (value) => {
        scrollTo(0);
        setActualPage(value);
        setPage(value);
    }

    const location = useLocation();
    const isDashboardPlatformsGeneral = location.pathname === '/dashboard/platforms/general';
    
    return (
        <div className="dashboardCard row d-flex justify-content-between">
            <div className='m-1'>{translate("global.page")} {actualPage} / {pages}</div>

            
            {!isDashboardPlatformsGeneral && itemsFound.length > 0 && 
                <button className="btn btn-sm btn-outline-blue m-1" onClick={() => exportData(itemsFound)}>{translate("global.exportPDF")}</button>
            }

            { itemName === translate("global.packages") &&
                <button className="btn btn-sm btn-fucsia m-1" onClick={ () => handleCancelBusqueda() }>{translate("global.return")}</button>
            }

            <div className="btn-group m-1">
                <button type="button" disabled={ actualPage > 2 ? false : true } className="btn btn-sm btn-blue" onClick={ () => handlePagination(1) }><IoIcons.IoIosArrowDropleftCircle size={16} /></button>
                <button type="button" disabled={ actualPage > 1 ? false : true } className="btn btn-sm btn-blue" onClick={ () => handlePagination(actualPage - 1) }><IoIcons.IoIosArrowDropleft size={16} /></button>
                <button type="button" disabled={ actualPage < pages ? false : true } className="btn btn-sm btn-blue" onClick={ () => handlePagination(actualPage + 1) }><IoIcons.IoIosArrowDropright size={16} /></button>
                <button type="button" disabled={ actualPage < (pages - 1) ? false : true } className="btn btn-sm btn-blue" onClick={ () => handlePagination(pages) }><IoIcons.IoIosArrowDroprightCircle size={16} /></button>
            </div>
        </div>
    )
}

export default ItemsPagination;