import { useCallback } from "react";
import { getClientDomain, getToken } from "../utils/Common";

export const useSystem = () => {

    const getCarriers = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain()}/system/carriers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, []);

    const getPaymentMethodsUsersBLI = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain()}/system/paymentMethodsUsersBLI`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, []);


    return { getCarriers, getPaymentMethodsUsersBLI }
}