import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BiLogIn, BiHome } from "react-icons/bi";
import { FiUserPlus } from "react-icons/fi";
import { Link, useLocation  } from 'react-router-dom';
import "./index.css";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [active, setActive] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const menuItems = [
    {
      title: "Home",
      url: "/",
      cName: "nav-links col-sm-6 col-lg-3",
      iconRef: <BiHome />,
    },
    {
      title: "Planes",
      url: "/planes",
      cName: "nav-links col-sm-6 col-lg-3",
      iconRef: <BiHome />,
    },
    {
      title: "Contacto",
      url: "#footer",
      cName: "nav-links col-sm-6 col-lg-3",
      iconRef: <BiHome />,
    },
    {
      title: "Paquetería",
      url: "https://store.myboxit.com/home",
      cName: "nav-links col-sm-6 col-lg-3",
      iconRef: <BiHome />,
    },
  ];

  const handleClick = () => {
    setActive(!active);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const isMobile = window.innerWidth <= 860;

      // Actualiza el estado isScrolling
      setIsScrolling(scrollY > 0);

      // Establece la variable isSticky en función del desplazamiento y el tamaño de la pantalla
      if (isMobile && scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Agrega un event listener para el scroll
    window.addEventListener("scroll", handleScroll);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`navbar ${isSticky ? "navbar-sticky" : ""} ${
          isScrolling ? "navbar-scrolling" : ""
        }`}
      >
        <div className={`navbar-logo ${isScrolling ? "logo-scrolling" : ""}`}>
          <img src="../img/logo-boxit.png" alt="Boxit" width="130" />
        </div>
        <div className="menu-icon" onClick={handleClick}>
          {active ? <AiOutlineClose /> : <GiHamburgerMenu />}
        </div>

        <div className={active ? "nav-menu active navbar-sticky" : "nav-menu"}>
          {menuItems.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.url} className={`nav-links col-sm-6 col-lg-3 ${item.url === currentPath && "active" }`}>
                  {item.title}
                </a>
                {active && <hr className="" />}
              </li>
            );
          })}
          <div className="btns-login-mobile">
           
            <Link to={"/login"} className="none-links mr-2">
              <button className="btn-login nav-links">
                <BiLogIn className="mr-2" /> Inicia sesión
              </button>
            </Link>
         
           <Link to={"/registrar"} className="none-links">
              <button className="btn-register nav-links">
                <FiUserPlus className="mr-2" /> Crea tu cuenta
              </button>
            </Link>           
          </div>
        </div>

        <div>
          <a href={"/login"} className="mr-2">
            <button className="btn-login">
              <BiLogIn className="mr-2" /> Inicia sesión
            </button>
          </a>
          <a href={"/registrar"}>
            <button className="btn-register">
              <FiUserPlus className="mr-2" /> Crea tu cuenta
            </button>
          </a>
        </div>
      </nav>
    </>
  );
};

export default Header;
