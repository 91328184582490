import { useState, useEffect } from "react";
import Spinner from "../Spinner";
import { statusOffline, statusOnline, translate } from "../../utils/Common";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight, FaTimes } from "react-icons/fa";
import "./index.css";
import ItemStatusPlatforms from "./ItemStatusPlatforms";
import ItemPackageLockers from "./ItemPackageLockers";

// estatus de las plataformas
const TablePaginationPlatforms = ({
  data,
  headerRow,
  tittle,
  filterStatus,
  isActivePackage
}) => {

  const [currentChunk, setCurrentChunk] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);
  const [filterType, setFilterType] = useState("");

  useEffect(() => {
    // Filter platforms when search value or filter type changes
    const normalizedSearchValue = normalizeString(searchValue);
    const newFilteredPlatforms = data
      ? data.filter((platform) =>
          normalizeString(platform.Plataforma).includes(normalizedSearchValue)
        )
      : [];

    // Apply additional filter based on filterType
    if (filterType === "active") {
      setFilteredPlatforms(
        newFilteredPlatforms.filter((p) => p.Estatus === "ONLINE")
      );
    } else if (filterType === "inactive") {
      setFilteredPlatforms(
        newFilteredPlatforms.filter((p) => p.Estatus === "OFFLINE")
      );
    } else {
      setFilteredPlatforms(newFilteredPlatforms);
    }

    setCurrentChunk(0); // Reset currentChunk when search or filter changes
  }, [searchValue, data, filterType]);

  const renderStatusRows = (platforms) => {
    return platforms.map((item, index) => (!isActivePackage ? <ItemStatusPlatforms item={item} index={index}/> : <ItemPackageLockers item={item} index={index}/>));
  };
  

  const handleFilterClick = (type) => {
    setFilterType(type);
    setSearchValue("");
  };

  const handleRemoveFilter = () => {
    setFilterType("");
    setSearchValue("");
  };

  const renderBtnActive = (
    <div
      className="d-flex pr-2 btnStatusPlatform"
      onClick={() => handleFilterClick("active")}
    >
      <div>{statusOnline}</div>{" "}
      <strong className="pr-1">
        {data?.filter((p) => p.Estatus === "ONLINE").length}
      </strong>{" "}
        ONLINE
    </div>
  );

  const renderBtnInactive = (
    <div
      className="d-flex pr-2 btnStatusPlatform"
      onClick={() => handleFilterClick("inactive")}
    >
      <div>{statusOffline}</div>{" "}
      <strong className="pr-1">
        {data?.filter((p) => p.Estatus === "OFFLINE").length}
      </strong>{" "}
      OFFLINE
    </div>
  );

  const renderHeader = data ? (
    data.length > 0 ? (
      <div className="d-flex justify-content-between pt-2">
        <div>
          <strong className="pr-1">{data.length}</strong>{" "}
          {translate("global.platforms")}
        </div>

        {filterStatus && !isActivePackage && (
          <>
            <div className="d-flex">
              {filterType === "active" && renderBtnActive}{" "}
              {filterType === "inactive" && renderBtnInactive}
              {filterType === "" && (
                <>
                  {renderBtnActive}
                  {renderBtnInactive}
                </>
              )}
            </div>
            {filterType && !isActivePackage && (
              <div
                className="d-flex btnStatusPlatform"
                onClick={handleRemoveFilter}
              >
                <div>
                  <FaTimes style={{ cursor: "pointer" }} />
                </div>
                <strong className="pr-1">{translate("global.Remove")}</strong>
              </div>
            )}
          </>
        )}
      </div>
    ) : (
      <div className="d-flex justify-content-between pt-2">
        <div>
          <strong className="pr-1">0</strong> {translate("global.platforms")}
        </div>
        {filterStatus && !isActivePackage(
          <div className="d-flex">
            <div className="d-flex pr-2">
              <div>{statusOnline}</div> <strong className="pr-1">0</strong>{" "}
              ONLINE
            </div>
            <div className="d-flex">
              <div>{statusOffline}</div> <strong className="pr-1">0</strong>{" "}
              OFFLINE
            </div>
          </div>
        )}
      </div>
    )
  ) : (
    <div className="d-flex justify-content-between pt-2">
      <div>
        <strong className="pr-1">-</strong> {translate("global.platforms")}
      </div>
      {filterStatus && (
        <div className="d-flex">
          <div className="d-flex pr-2">
            <div>{statusOnline}</div> <strong className="pr-1">-</strong>{" "}
            ONLINE
          </div>
          <div className="d-flex">
            <div>{statusOffline}</div> <strong className="pr-1">-</strong>{" "}
            OFFLINE
          </div>
        </div>
      )}
    </div>
  );

  const totalChunks = filteredPlatforms
    ? Math.ceil(filteredPlatforms.length / 10)
    : 0;

  const handlePrevClick = () => {
    setCurrentChunk((prevChunk) => Math.max(prevChunk - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentChunk((prevChunk) => Math.min(prevChunk + 1, totalChunks - 1));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const renderButtonsActions = (
    <>
      <button
        className="btn btn-outline-light btn-link"
        onClick={handlePrevClick}
        disabled={currentChunk === 0}
      >
        <FaAngleLeft />
      </button>
      <button
        className="btn btn-outline-light btn-link"
        onClick={handleNextClick}
        disabled={currentChunk === totalChunks - 1}
      >
        <FaAngleRight />
      </button>
    </>
  );

  const renderSearch = (
    <input
      type="text"
      className="form-control"
      placeholder={`${translate("global.search")}...`}
      style={{ fontSize: "12px" }}
      value={searchValue}
      onChange={handleSearchChange}
    />
  );

  const renderPageInfo = (
    <div className="d-flex justify-content-center pt-2">
      <span>
        {" "}
        {translate("global.page")} {currentChunk + 1} {translate("global.of")}{" "}
        {totalChunks}
      </span>
    </div>
  );

  return (
    <>
      <div className="d-flex justify-content-left pl-3">
        <h6>{tittle}</h6>
      </div>

      <div className="pl-3 pr-3 pb-3 pt-2">
        <div className="row">
          <div className="col-8">{renderSearch}</div>
          <div className="col-4">{renderButtonsActions}</div>
        </div>

        {renderHeader}
      </div>

      <table className="table table-borderless">
        <thead>{headerRow}</thead>
        <tbody>
          {data ? (
            filteredPlatforms.length > 0 ? (
              <>
                {renderStatusRows(
                  filteredPlatforms.slice(
                    currentChunk * 10,
                    (currentChunk + 1) * 10
                  )
                )}
              </>
            ) : (
              <tr>
                <td className="p-1 font-small" colSpan="3">
                  <div className="mx-2">
                    <span>
                      {translate("dashboard.home.status_platforms.asign")}
                    </span>
                  </div>
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td className="p-1 font-xsmall td-spinner">
                <Spinner type={1} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data ? renderPageInfo : ""}
    </>
  );
};

export default TablePaginationPlatforms;
