import React, { useState, useContext, useEffect, useRef } from 'react';
import AuthContext from '../../../contexts/auth';
import { setValueSearchFilter, setPage, translate } from '../../../utils/Common';
import { useForm } from 'react-hook-form';
import * as BsIcons from 'react-icons/bs';
import PackagesReservation from '../../../components/PackagesReservation';
import ItemReserva from '../../../components/ShowItems/ItemReserva';
import ProviderList from '../../../components/ProviderList';
import './index.css';

const Reservation = () => {
    const { register, watch, setValue } = useForm();
    const provider = useRef({});
    provider.current = watch('proveedor', '');
    const userContext = useContext(AuthContext);

    // update table
    const [updateTable, setUpdateTable] = useState(false);

    // para obtener los proveedores
    const [selectedUser, setSelectedUser] = useState(null);
    useEffect(() => {
        const handleUserChange = () => {
            setSelectedUser((provider.current && provider.current !== "Seleccione" && provider.current !== "No hay usuarios") ? Number(provider.current) : 0);
            setUpdateTable(!updateTable);
        }
        handleUserChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider.current])

    useEffect(() => {
        setPage('')
        setValueSearchFilter('');
    }, []);

    return (
        <>
            <div className="dashboardCard">
                <div className={`reservations-title text-left ${userContext.user?.IsAdmin ? 'mb-2' : ''}`}>
                    <h5><BsIcons.BsInboxesFill size={16} className="mr-2"/>{translate("dashboard.lockers.reservations.title")}</h5>
                    <div className="clearfix border-bottom"></div>
                </div>

                { userContext.user?.IsAdmin &&
                    <div className="col-sm-12 col-md-6 mb-0 px-1 text-left">
                        <label htmlFor="selectProveedor" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("global.business")}:</label>
                        <ProviderList 
                            register={register}
                            nameShow={translate("global.select")}
                            idShow={"all"}
                            readOnly={false}
                            setValue={setValue}
                            valueId={"proveedor"}/>
                    </div>
                }
            </div>

            <PackagesReservation
                selectedUser={ selectedUser }
                updateTable={ updateTable }
                handleUpdateTable={ setUpdateTable }
                ItemComponent={ ItemReserva }
                reservationType={ 1 }/>
        </>
    );
}

export default Reservation;