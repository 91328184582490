import React, { useEffect, useState } from 'react';
import SidebarContext from './index';
import { getNavigationPage, setNavigationPage } from '../../utils/Common';

const SidebarProvider = ({ children }) => {
	// para saber si el sidebar esta abierto
	const [sidebarOpen, setSidebarOpen] = useState(true);
	// para saber qué pagina estoy
	const [currentPage, setCurrentPage] = useState(getNavigationPage() ? getNavigationPage() : "Inicio");
	// para saber si es un dispositivo movil
	const [isMobile, setIsMobile] = useState(false);

	const showSidebar = (value) => setSidebarOpen(value);
	const closeSidebar = () => setSidebarOpen(false);
    const handleChangeCurrentPage = (value) => { setCurrentPage(value); setNavigationPage(value); }

	useEffect(() => {
		const handleResize = () => window.innerWidth < 601 ? setIsMobile(true) : setIsMobile(false);

		window.addEventListener('resize', handleResize);
		
		if (isMobile) setSidebarOpen(false);

		return () => window.removeEventListener('resize', handleResize);
	}, [isMobile]);

	useEffect(() => {    
		if (window.innerWidth < 601) setIsMobile(true);
	}, []);

    return (
        <SidebarContext.Provider value={{ handleChangeCurrentPage, showSidebar, closeSidebar, currentPage, sidebarOpen, isMobile }}>
            { children }
        </SidebarContext.Provider>
    );
}

export default SidebarProvider;