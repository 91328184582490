import { useState, useEffect, useMemo } from "react";

const ViewCountryFlag = () => {
  // Definir países con configuración
  const countries = {
    pa: { name: "PANAMÁ", flag: "/img/pa_flag.png", actived: true },
    br: { name: "BRASIL", flag: "/img/br_flag.png", actived: true },
  };

  // Obtener país almacenado en localStorage o establecer uno predeterminado
  const [selectedCountry, setSelectedCountry] = useState(null);
  
  useEffect(() => {
    // Obtener país almacenado en localStorage al montar el componente
    const storedCountry = localStorage.getItem("country");
    setSelectedCountry(storedCountry);
  }, []);

  // Renderizar la lista de países
  return (
    <div className="d-flex pr-2">
        {selectedCountry &&  <img src={countries[selectedCountry].flag} alt={`${countries[selectedCountry].name}_flag`} style={{height:"30px", background:"white", padding:"1px", borderRadius:"50px"}}/> }
    </div>
  );
};

export default ViewCountryFlag;
