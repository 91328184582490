import { useCallback } from "react";
import { getClientDomain, getToken } from "../utils/Common";

export const useClient = () => {

    const getClientsByFilter = useCallback(async (signal, data) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/clients/filter`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.itemsFind?.length > 0) return (result.payload);
        else return ({
            ...result.payload,
            itemsFind: []
        });
    }, []);

    const getDetailsClient = useCallback(async (signal, data) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/clients/details`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return result.payload[0];
        else return {...result, payload: []};
    }, []);


    const updateDetailsClient = useCallback(async (signal, data) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/clients/update`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return result.payload[0];
        else return {...result, payload: []};
    }, []);

    const getTypePlans = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/clients/typePlans`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return result.payload;
        else return [];
    }, []);

    const getRolesBli = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/clients/rolesBli`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return result.payload;
        else return [];
    }, []);

    const createClient = useCallback(async (signal, data) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/clients/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result.payload.length > 0) {
            if (result.payload[0].Mensaje === 'USER_CREATED_SUCCESSFULLY') {
                return 'SIGNUP_SUCCESSFULLY';
            }
            else if (result.payload[0].Mensaje === 'USER_OR_EMAIL_ALREADY_EXIST') {
                if (result.payload[0].UsuarioValidator) return 'USERNAME_ALREADY_EXIST';
                if (result.payload[0].EmailValidator) return 'EMAIL_ALREADY_EXIST';
            }
        }
      
    }, [])

    return { getClientsByFilter, getDetailsClient, updateDetailsClient, getTypePlans, getRolesBli, createClient}
}