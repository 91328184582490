import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { getToken, getClientDomain, round, translate } from '../../../../utils/Common';
import { useForm } from 'react-hook-form';
import AuthContext from '../../../../contexts/auth';
import ModalContext from '../../../../contexts/modal';
// components
import FieldSizes from "./FieldSizes";
import LockerSizes from "./LockerSizes";
import SearchClient from "./SearchClient";
import PlatformList from '../../../../components/PlatformList';
import DoorList from '../../../../components/DoorList';
import ProviderList from '../../../../components/ProviderList';
import { usePackages } from '../../../../hooks/usePackages';
import PlatformSearchList from '../../../../components/PlatformSearchList';

// formulario para carga tipo proveedor
const FormProveedores = (props) => {
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext);
    const { register, errors, clearErrors, setError, reset, handleSubmit, watch, setValue } = useForm();
  const {createPackage} = usePackages();
    // para saber qué unidades de medida utilizar --> 0 = cm/kg, 1 = inch/lb
    const [unidad, setUnidad] = useState(1);
    // para saber el tipo de ingreso de medidas
    const [ingresarMedidas, setIngresarMedidas] = useState(false);

    // calcular el precio total
    const [precioLibra, setPrecioLibra] = useState(0);
    const [cargoAduana, setCargoAduana] = useState(0);
    const [otrosCargos, setOtrosCargos] = useState(0);
    // calcular peso
    const [pesoReal, setPesoReal] = useState(0);
    const [pesoVol, setPesoVol] = useState(0);
    
    // variables usadas para la busqueda del cliente
    const [buscarCliente, setBuscarCliente] = useState(null);
    const [selectClientes, setSelectClientes] = useState(null);
    const [providor, setProvidor] = useState(null);
  const [platformSelect, setPlatformSelect] = useState(null);

  const [selectedClient, setSelectedClient] = useState(null);
  const handleSelectedClient = (value) => {
    if(selectClientes){
      const client = selectClientes.filter(
        (client) => client.IdCliente === Number(value)
      )[0];
      setSelectedClient(client ? client : null);
    }
  };

    // para saber qué boxit se seleccionó
    const [isBoxit, setIsBoxit] = useState(false);
    const idPlatform = useRef({});
    idPlatform.current = watch("plataforma","");
    const handleIsBoxit = useCallback((value) => setIsBoxit(value), []);

    // clear formulario
    const resetFormProveedores = () => {
        reset();
        clearErrors();

        setIngresarMedidas(false);
        setUnidad(1);
        setPrecioLibra(0);
        setCargoAduana(0);
        setOtrosCargos(0);
        setPesoReal(0);
        setPesoVol(0);
        setBuscarCliente('');
        setSelectClientes(null);
        setIsBoxit(false);
    }
    
    const [isSendingData, setIsSendingData] = useState(false);
    // registrar paquete en el servidor
    const sendPackage = async (data) => {
        try {
            setIsSendingData(true);
            if (!data.cliente || data.cliente === '') {
                setError('buscarCliente', {
                    type: '',
                    message: translate("dashboard.packages.loadpackage.formProvider.searchClient")
                });
                return;
            }

            // validar peso
            let pesoRealValidator = false;
            let pesoVolValidator = false;
            if (!userContext.user?.IsAdmin) {
                let precioLb = false;
                data.proveedor = userContext.user?.Ruc;                
                data.idTipoUsuario = userContext.user?.IdTipoUsuario;

                if (!data.pesoReal || data.pesoReal === "0") {
                    setError('pesoReal', {
                        type: '',
                        message: translate("dashboard.packages.loadpackage.formProvider.weightLb")
                    });
                    pesoRealValidator = true;
                }
                
                if (!data.pesoVol || data.pesoVol === "0") {
                    setError('pesoVol', {
                        type: '',
                        message: translate("dashboard.packages.loadpackage.formProvider.weightVol")
                    });
                    pesoVolValidator = true;
                }

                if (data.precioLibra === "0") {
                    setError('precioLibra', {
                        type: '',
                        message: translate("dashboard.packages.loadpackage.formProvider.costLb")
                    });
                    precioLb = true;
                }
                
                if (pesoRealValidator || pesoVolValidator || precioLb) return;
            }
            else {
                if (!data.pesoReal) {
                    setError('pesoReal', {
                        type: '',
                        message: translate("dashboard.packages.loadpackage.formProvider.weightLb")
                    });
                    pesoRealValidator = true;
                }
                
                if (!data.pesoVol) {
                    setError('pesoVol', {
                        type: '',
                        message: translate("dashboard.packages.loadpackage.formProvider.weightVol")
                    });
                    pesoVolValidator = true;
                }
                
                if (pesoRealValidator || pesoVolValidator) return;

                data.cliente = selectedClient ? selectedClient?.IdCliente : selectClientes[0]?.IdCliente
            }

            // validar datos a enviar
            if (data.pesoVol > 15) {
                if (data.pesoVol > data.pesoReal) data.peso = data.pesoVol;
                else data.peso = data.pesoReal;
            }
            else {
                data.peso = data.pesoReal;
            }
            if (data.puerta) {
                if (data.puerta === 'No hay puertas disponibles' || data.puerta === 'Seleccione') data.puerta = '';
            }
            if (unidad) {
                if (data.alto) data.alto = round(data.alto * 2.54, 2);
                if (data.largo) data.largo = round(data.largo * 2.54, 2);
                if (data.ancho) data.ancho = round(data.ancho * 2.54, 2);
            }
            else {
                if (data.peso) data.peso = Math.ceil(data.peso * 2.205);
            }

            data.idTipoPaquete= props.idTipoPaquete 

            const controller = new AbortController();
            const result = await createPackage(data, controller.signal);

            if (result) {
                if (result.code === 200) {
                    if (result.payload.Procesado === '1') {
                        modalContext.confirm(
                            translate("dashboard.packages.loadpackage.formProvider.successTittle"), 
                            result.payload.PackageNumber ? (
                                <div>
                                      {translate("dashboard.packages.loadpackage.formProvider.successInfo")}
              
                                      <div>
                                          {translate("global.numPackageIs")} <span style={{ fontWeight: "bold" }}>{result.payload.PackageNumber}</span>
                                      </div>
                                  
                                </div>
                              ) : (
                                translate(
                                  "dashboard.packages.loadpackage.formProvider.successInfo"
                                )),
                            <button type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                            true);
                        resetFormProveedores();
                    } if(result.payload.Mensaje==="RESERVATION MADE SUCCESSFULLY"){
                        modalContext.confirm(
                          translate(
                            "dashboard.packages.loadpackage.formProvider.successTittle"
                          ),
                          <div>
                            {translate("dashboard.packages.loadpackage.formProvider.successInfoReserved")}
                            <div>{translate("global.status.reservedDoor")} : <span style={{fontWeight:"bold"}}>{result.payload.Locker}</span></div>
                          </div>,
                          <button
                            type="button"
                            className="btn btn-sm btn-fucsia"
                            onClick={() => modalContext.setIsOpenModal(false)}
                          >
                            {translate("global.accept")}
                          </button>,
                          true
                        );
                        resetFormProveedores();
                      } 
                    else {
                        const errorHandlers = {
                            'El número de paquete ya se encuentra registrado.': () => setError('paquete', { type: '', message: translate("dashboard.packages.loadpackages.formProvider.packageRegistered") }),
                            'El número de tracking ya se encuentra registrado.': () => setError('tracking', { type: '', message: translate("dashboard.packages.loadpackages.formProvider.trackingRegistered") }),
                            'No tienes autorización para entregar en este Boxit.': () => setError('plataforma', { type: '', message: translate("dashboard.packages.loadpackages.formProvider.notAuthorized") })
                        };

                        const errorMessage = result.payload.Mensaje;
                        if (errorHandlers[errorMessage]) {
                                errorHandlers[errorMessage]();
                        }
                    }
                }
                else modalContext.confirm(
                    translate("global.alerts.ops"), 
                    translate("dashboard.packages.loadpackages.formProvider.errorRegisterPackage"),
                    <button type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                    true);
            }
        } catch (err) {
            console.log(err);
            modalContext.confirm(
                translate("global.alerts.ops"), 
                translate("dashboard.packages.loadpackages.formProvider.errorRegisterPackage"),
                <button type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        } finally {
            setIsSendingData(false);
        }
    }

    // calcular el monto total
    const [costoTotal, setCostoTotal] = useState(0);
    useEffect(() => {
        let peso = 0;
                
        if (pesoVol > 15) {
            if (pesoVol > pesoReal) peso = pesoVol;
            else peso = pesoReal;
        }
        else {
            peso = pesoReal;
        }

        setCostoTotal(otrosCargos + cargoAduana + round((precioLibra * (unidad ? peso : Math.ceil(peso * 2.205))), 2));
    }, [otrosCargos, cargoAduana, precioLibra, pesoReal, pesoVol, unidad]);

    return (
        <>
            <form className="carga-proveedores m-0 p-0" onSubmit={ handleSubmit(sendPackage) }>
                { userContext.user?.IsAdmin && 
                    <div className="col-sm-12 col-md-6 mb-2 px-1">
                        <label htmlFor="selectProveedor" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("global.business")}:</label>
                        <ProviderList 
                            register={register}
                            errors={errors}
                            nameShow={translate("global.select")}
                            idShow={props.idTipoUsuario}
                            readOnly={false} 
                            setValue={setValue}
                            valueId={"proveedor"}
                            setProveedor={setProvidor}/>
                    </div>
                }

{
            providor &&(
             
                <SearchClient   
                    errors={ errors }
                    setError={ setError }
                    clearErrors={ clearErrors }
                    register={ register }
                    idTipoCliente={ props.idTipoCliente }
                    buscarCliente={ buscarCliente }
                    handleBuscarCliente={ setBuscarCliente }
                    selectClientes={ selectClientes }
                    handleSelectClientes={ setSelectClientes } 
                    isProvider 
                    handleSelectedClient={handleSelectedClient}/>
            )
          }

                <div className="form-row mx-0">
                    <div className="form-group col-sm-12 col-md-6 mb-1">
                        <label htmlFor="inputNumeroPaquete" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("dashboard.packages.loadpackages.formProvider.numPackage")}:</label>
                        <input className={ "form-control form-control-sm font-small" + (errors.paquete ? " is-invalid" : "") } type="text" id="inputNumeroPaquete" name="paquete" ref={ register({
                            required: {
                                value: true,
                                message: translate("global.error.notValidPackages")
                            }
                        }) }/>
                        {errors.paquete && <span className="text-error">{errors.paquete.message}</span> }
                    </div>

                    <div className="form-group col-sm-12 col-md-6 mb-1">
                        <label htmlFor="inputNumeroTracking" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("dashboard.packages.loadpackages.formProvider.numTracking")}:</label>
                        <input className={ "form-control form-control-sm font-small" + (errors.tracking ? " is-invalid" : "") } type="text" id="inputNumeroTracking" name="tracking" ref={ register({
                            required: {
                                value: true,
                                message: translate("dashboard.packages.loadpackages.formProvider.notValidTracking")
                            }
                        }) }/>
                        {errors.tracking && <span className="text-error">{errors.tracking.message}</span> }
                    </div>
                </div>

                <div className="form-row mx-0">
                    <div className="form-group col-12 col-md-8 mb-1">
                        <label htmlFor="inputDescripcion" className="mb-1 mt-2">{translate("global.description")}: <span className="font-xsmall">({translate("global.optional")})</span></label>
                        <textarea className="form-control form-control-sm font-small" rows="2" id="inputDescripcion" name="descripcion" ref={ register }/>
                    </div>

                    <div className="form-group mb-1 mt-2 col-12 col-md-4">
                        <div className="custom-control custom-switch">
                            <input className="custom-control-input" type="checkbox" checked={ ingresarMedidas ? true : false } onChange={ (e) => setIngresarMedidas(e.target.checked) } id="inputTipoIngresoMedidas"/>
                            <label htmlFor="inputTipoIngresoMedidas" className="custom-control-label">{translate("dashboard.packages.loadpackages.formProvider.packageMeasures")}</label>
                        </div>
                    </div>
                </div>

                <div className="form-row mx-0">
                    <div className="form-group mb-1 col-12 col-md-4">
                        <label htmlFor="inputCourier" className="mb-1 mt-2">{translate("global.courier")}: <span className="font-xsmall">({translate("global.optional")})</span></label>
                        <input className="form-control form-control-sm font-small" type="text" id="inputCourier" name="courier" ref={ register }/>
                    </div>
                    
                    {ingresarMedidas &&  <div className={ "form-group mb-1 " + (ingresarMedidas ? "col-6 col-md-3" : "col-6 col-md-2") }>
                        <label htmlFor="selectSistemaMedida" className="mb-1 mt-2">{translate("global.units")}:</label>
                        <select className="form-control form-control-sm font-small" onChange={ (e) => setUnidad(e.target.value === 'cm / kg' ? 0 : 1) } id="selectSistemaMedida" >
                            <option defaultValue>inch / lb</option>
                            <option>cm / kg</option>
                        </select>
                    </div> }
                   

                    <div className={ "form-group mb-1 " + (ingresarMedidas ? "col-6 col-md-2" : "col-6 col-md-2") }>
                        <label htmlFor="inputPesoReal" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("global.weight")} <span className="font-small">({ unidad === 0 ? 'kg' : 'lb' })</span>:</label>
                        <input className={ "form-control form-control-sm font-small" + (errors.pesoReal ? " is-invalid" : '') } 
                            type="number" id="inputPesoReal" placeholder="0" name="pesoReal" onChange={ (e) => setPesoReal(e.target.value ? e.target.value : 0) } ref={ register(
                                // required: {
                                //     value: true,
                                //     message: 'Debes introducir un peso lb.'
                                // }
                            ) }/>
                        { errors.pesoReal && <span className="text-error">{errors.pesoReal.message}</span> }
                    </div>

                    <div className={ "form-group mb-1 " + (ingresarMedidas ? "col-6 col-md-2" : "col-6 col-md-2") }>
                        <label htmlFor="inputPesoVol" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("global.weight")} <span className="font-small">(vol)</span>:</label>
                        <input className={ "form-control form-control-sm font-small" + (errors.pesoVol ? " is-invalid" : '') }
                            type="number" id="inputPesoVol" placeholder="0" name="pesoVol" onChange={ (e) => setPesoVol(e.target.value ? e.target.value : 0) } ref={ register(
                                // required: {
                                //     value: true,
                                //     message: 'Debes introducir un peso vol.'
                                // }
                            ) }/>
                        {errors.pesoVol && <span className="text-error">{errors.pesoVol.message}</span>}
                    </div>

                    { !ingresarMedidas &&
                        <div className={ "form-group mb-1 col-3 " + (ingresarMedidas ? "col-md-3" : "col-6 col-md-2") }>
                            <label htmlFor="selectTamano" className="mb-1 mt-2">{translate("global.size")}:</label>
                            <select className="form-control form-control-sm font-small" id="selectTamano" name="size" ref={ register }>
                                <option defaultValue>P</option>
                                <option>M</option>
                                <option>G</option>
                            </select>
                        </div>
                    }
                </div>

                { ingresarMedidas &&
                    <FieldSizes register={ register }
                                unidad={ unidad } />
                }

                <div className="form-row mx-0">
                    <div className="form-group col-sm-12 col-md-6 mb-1">
                        <label htmlFor="selectBoxit" className="mb-1 mt-2"><span className="text-error text-bold">*</span> Boxit:</label>
                       

                             <PlatformSearchList
            register={register} 
            errors={errors}
            setPlatformSelect={setPlatformSelect}
            platfSelect={platformSelect} 
            valueId={"plataforma"} 
            readOnly={false}
            setValue={setValue}
            idPlatform={idPlatform.current}
            handleIsBoxit={handleIsBoxit}
            defaultPlatformId={props.idPlatformSelect}
            />
                    </div>

                    {userContext.user?.IsAdmin && isBoxit && idPlatform.current !== 'Seleccione' && idPlatform.current !== '' &&
                        <div className="form-group col-sm-12 col-md-6 mb-1">
                            <label htmlFor="selectPuertas" className="mb-1 mt-2">{translate("dashboard.packages.loadpackages.formProvider.doorsAvailable")}:</label>
                            <DoorList 
                                errors={ errors }
                                register={ register }
                                nameShow={translate("global.select")}
                                idShow={ idPlatform.current }
                                readOnly={ false }
                                isBoxit />
                        </div>
                    }
                </div>

                <div className="form-row mx-0">
                    { userContext.user?.IsAdmin &&
                        <div className="form-group col-6 col-md-3 mb-1">
                            <label htmlFor="inputOtrosCargos" className="mb-1 mt-2">{translate("dashboard.packages.loadpackages.formProvider.othersCharges")} <span className="font-xsmall">(B/)</span>:</label>
                            <input className="form-control form-control-sm font-small" onChange={ (e) => setOtrosCargos(Number(e.target.value ? e.target.value : 0)) } type="number" step=".01" id="inputOtrosCargos" 
                                placeholder="0" name="otrosCargos" ref={ register }/>
                            {errors.otrosCargos && <span className="text-error">{errors.otrosCargos.message}</span> }
                        </div>
                    }

                    <div className="form-group col-6 col-md-3 mb-1">
                        <label htmlFor="inputCargoAduanas" className="mb-1 mt-2">{translate("dashboard.packages.loadpackages.formProvider.customsCharge")} <span className="font-xsmall">(B/)</span>:</label>
                        <input className="form-control form-control-sm font-small" onChange={ (e) => setCargoAduana(Number(e.target.value ? e.target.value : 0)) } type="number" step=".01" id="inputCargoAduanas" 
                            placeholder="0" name="cargoAduana" ref={ register }/>
                        {errors.cargoAduana && <span className="text-error">{errors.cargoAduana.message}</span> }
                    </div>

                    <div className="form-group col-6 col-md-3 mb-1">
                        <label htmlFor="inputPrecio" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("dashboard.packages.loadpackages.formProvider.costLb")} <span className="font-xsmall">(B/)</span>:</label>
                        <input className={ "form-control form-control-sm font-small" + (errors.precioLibra ? " is-invalid" : '') } onChange={ (e) => setPrecioLibra(Number(e.target.value ? e.target.value : 0)) } type="number" step=".01" id="inputPrecio" 
                            placeholder="0" name="precioLibra" ref={ register({
                                required: {
                                    value: true,
                                    message: translate("dashboard.packages.loadpackages.formProvider.costLb.message")
                                }
                            }) }/>
                        {errors.precioLibra && <span className="text-error">{errors.precioLibra.message}</span> }
                    </div>

                    <div className="form-group col-6 col-md-3 mb-1">
                        <label htmlFor="inputCostoProv" className="mb-1 mt-2">{translate("dashboard.packages.loadpackages.formProvider.costTotal")}<span className="font-xsmall">(B/)</span>:</label>
                        <input className="form-control-plaintext form-control-sm" value={ costoTotal } readOnly type="number" step=".01" id="inputCostoProv" 
                            placeholder="0" name="costoProv" ref={ register }/>
                            {errors.costoProv && <span className="text-error">{errors.costoProv.message}</span> }
                    </div>
                </div>

                <div className="form-row mx-0 d-flex justify-content-center align-items-center">
                    <div className="form-group col-sm-12 col-md-8 m-0 text-center">
                        <button className="btn btn-outline-fucsia m-2" type="reset" onClick={ () => resetFormProveedores() }>{translate("global.clean")}</button>
                        <button className="btn btn-fucsia m-2" type="submit" disabled={ isSendingData } >{translate("global.toRegister")}</button>
                    </div>
                </div>

                <LockerSizes unidad={ unidad } />
    
            </form>
        </>
    );
}

export default FormProveedores;