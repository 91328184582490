import { PieChart, Pie, Cell, Label, ResponsiveContainer, Tooltip } from 'recharts';
import Spinner from '../../../../components/Spinner';
import CustomTooltip from '../../../../components/Tooltip';
import './index.css';
import {translate} from "../../../../utils/Common"

// graph paquetes en las plataformas
const GraphPackagesInPlatforms = ({ cantPackagesInLocker }) => {
    const calcularSumaCantPaquetes = () => {
        if (!cantPackagesInLocker) return 0; 

        let sumaCantPaquetes = 0;
        cantPackagesInLocker.forEach(plataforma => {
            sumaCantPaquetes += plataforma.CantPaquetes;
        });
        return sumaCantPaquetes;
    };

    return (
        // Renderizar el Spinner mientras se carga la información
        cantPackagesInLocker === null ? (
            <Spinner type={1} />
        ) : (
            <>
                <div className="d-flex items-align-between">
                    <h5 className="p-0 mt-0 mx-2 mb-2 text-bold">{translate("dashboard.home.packages_platforms.title")}</h5>
                    <CustomTooltip
                        className="mt-1"
                        info={translate("dashboard.home.packages_platforms.info_1") + translate("dashboard.home.packages_platforms.info_2")}
                    />
                </div>

                {/* Mostrar la suma de CantPaquetes */}
                <h2>{calcularSumaCantPaquetes()}</h2>
            </>
        )
    );
};

export default GraphPackagesInPlatforms;