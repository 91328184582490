import { getToken, getClientDomain, translate } from '../../../../utils/Common';
import AuthContext from '../../../../contexts/auth';
import { useContext, useEffect, useState } from 'react';
import Spinner from '../../../../components/Spinner';
// components
// import Tooltip from '../../../../../components/Tooltip';

// para buscar clientes
const SearchClient = (props) => {
    const userContext = useContext(AuthContext);
    const [selectedClient, setSelectedClient] = useState(null);
    const [idSelectClient, setIdSelectedClient] = useState('');

    const getClients = async () => {        
        try {
            // if (props.buscarCliente === '' || !props.buscarCliente) {
            //     props.setError('buscarCliente', {
            //         type: '',
            //         message: translate("dashboard.packages.loadpackage.searchClient.inputData")
            //     });
            //     return ;
            // }

            props.clearErrors();

            const abortController = new AbortController();
            const response = await fetch(`${getClientDomain(2)}/dashboard/clients/search` , {
                method: 'POST',
                connection: 'keep-alive',
                accept: 'application/json',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify({ 
                    buscarCliente: (props.buscarCliente ? props.buscarCliente.toUpperCase() : null),
                    idUsuario: props?.idUsuarioSelected ?? userContext.user?.Ruc,
                    idTipoCliente: props.idTipoCliente
                 }),
                signal: abortController.signal
            });

            const result = await response.json();
            if (result) {
                if (result.code === 200) {
                    if (result.payload) {
                        if (result.payload?.length > 0){
                            const idClientSelect = result.payload[0]?.IdCliente;
                            props.handleSelectClientes(result.payload);
                            setSelectedClient(result.payload[0]);
                            setIdSelectedClient(idClientSelect);
                            props.handleSelectedClient(idClientSelect);
                            props.handleBuscarCliente('');
                        } else{
                            props.handleSelectClientes([]);
                            setSelectedClient(null);
                            setIdSelectedClient('');
                            props.handleBuscarCliente('');
                        } 
                    } else {
                    props.handleSelectClientes([{ PrimerNombre: translate("global.thereNone"), PrimerApellido: translate("global.providers")}]);
                    props.handleBuscarCliente('');
                    setSelectedClient(null);
                    setIdSelectedClient('');
                }
                }
                else {
                    props.setError('buscarCliente', {
                        type: '',
                        message: translate("dashboard.packages.loadpackage.searchClient.noData")
                    });
                    props.handleSelectClientes(null);
                }
            }
        } catch (error) {
            console.log(error);
            props.setError('buscarCliente', {
                type: '',
                message: translate("dashboard.packages.loadpackage.searchClient.error")
            });
        }
    }

    useEffect(() => {
        getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idUsuarioSelected]);

    useEffect(() => {
        // Cuando se actualiza props.selectClientes, seleccionar automáticamente el primer cliente
        if (props.selectClientes && props.selectClientes.length > 0) {
            setSelectedClient(props.selectClientes[0]);
            setIdSelectedClient(props.selectClientes[0]?.IdCliente);

            props.handleSelectedClient(props.selectClientes[0]?.IdCliente);
        }
    }, [props.selectClientes]);

    return  <>
                <div className={ "form-row " + (props?.from === 'changeClient' ? "mx-3" : "mx-0")}>

                    {
                        props.isProvider && (
                            <div className="form-group col-sm-12 col-md-6 mb-1">
                            <label htmlFor="inputBuscarcliente" className={ "mb-1 " + (props?.from === 'changeClient' ? "mt-1" : "mt-2")}>
                            {translate("global.search")+" "+translate("global.client")}: 
                            </label>
                            <input className="form-control form-control-sm font-small" value={ props.buscarCliente ? props.buscarCliente : '' }
                                onChange={ (e) => props.handleBuscarCliente(e.target.value ? e.target.value : '') } 
                                type="text" name="buscarCliente" data-toggle="tooltip" data-placement="top" 
                                disabled={!props.selectClientes}
                                title="Nombre, apellido o número de cliente" ref={ props.register } />
                            { props.errors.buscarCliente && <span className="text-danger font-xsmall">{props.errors.buscarCliente.message}</span> }
                        </div>
                        )
                    }
                   

                    

                        <div className="form-group col-sm-12 col-md-6 mb-1">
                
                            <label htmlFor="selectCliente" className={ "mb-1 " + (props?.from === 'changeClient' ? "mt-1" : "mt-2")}><span className="text-error text-bold">*</span> {translate("global.client")}:</label>
                            <select className="form-control form-control-sm font-small" id="selectCliente" name="cliente" ref={ props.register({
                                validate: value => value !== ("Seleccione" || "No hay clientes") || 'Debes elegir un cliente válido.'
                            }) } 
                            onChange={(e) => {
                                props.handleSelectedClient(e.target.value); 
                                setIdSelectedClient(e.target.value);
                            }} 
                            
                            value={idSelectClient} disabled={!props.selectClientes}
                        >
                                {/* { props.selectClientes } */}
                                <option defaultValue>{translate("global.select")}</option>
                                { props.selectClientes?.length > 0 && props.selectClientes.map((cliente, index) => <option key={ cliente?.IdCliente } value={ cliente.IdCliente } onClick={()=>{setIdSelectedClient(cliente.IdCliente)}}>{ `${cliente.IdCliente} - ${cliente.Nombre} ${cliente.Apellidos}` }</option> ) }
                            </select>
                            { props.errors.buscarCliente && <span className="text-danger font-xsmall">{props.errors.buscarCliente.message}</span> }
                            { !props.selectClientes && !props.errors.buscarCliente && <div style={{ marginTop:"12px", display:"flex", alignItem:"center"}}>
                            <Spinner  type={1} size={15} />
                         </div>}         
                    </div>
                    
                    
                </div>

                <div className={ "form-row " + (props?.from === 'changeClient' ? "mx-3 mb-2" : "mx-0")}>
                    { props.buscarCliente && props.buscarCliente !== '' &&
                        <div className="form-group col-sm-12 col-md-6 mt-1 mb-1">
                            <button className="btn btn-outline-fucsia btn-sm" type="button" onClick={ () => getClients() }>{translate("global.search")+" "+translate("global.client")}</button>
                        </div>
                    }
                </div>
            </>
}

export default SearchClient;