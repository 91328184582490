import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../contexts/auth';
import { exportToPDF, getClasificacion, translate } from '../../utils/Common';
import Results from './Results';
import ItemsPagination from './ItemsPagination';
import './index.css';

const ShowItems = ({ 
    ItemComponent, itemsFound, itemName, showBox, handleCancelBusqueda, setSelectedItem, itemsShowing, view
}) => {
    const userContext = useContext(AuthContext);
    const [actualPage, setActualPage] = useState(Number(sessionStorage.getItem('currentPage')) ? Number(sessionStorage.getItem('currentPage')) : 1);
    const [pages, setPages] = useState(Math.ceil(itemsFound.length / itemsShowing));

    const exportData = (data) => {
        let columns = [ { title: translate("global.numPackage"), dataKey: "nroPaquete" },
        { title: translate("global.numTracking"), dataKey: "nroTracking" },
        { title: "ID "+translate("global.client"), dataKey: "idCliente" },
        { title: translate("global.client"), dataKey: "cliente" },
        { title: translate("global.sort"), dataKey: "clasificacion" },
        { title: translate("global.door"), dataKey: "identificacion" },
        { title: "Boxit", dataKey: "plataforma" } ];
        if (userContext?.user.IdTipoUsuario !== 1) columns.splice(2,1);

        let rows = [];
        if (data?.length) 
        data.map(paquete => {
            let paqueteRow = {};
            paqueteRow.nroPaquete = itemName === translate("global.packages") ? (paquete.Nro_Id ? paquete.Nro_Id : '') : (paquete.NumeroIdentificacion ? paquete.NumeroIdentificacion : '');
            paqueteRow.nroTracking = paquete.NumeroTracking ? paquete.NumeroTracking : '';
            if (userContext?.user.IdTipoUsuario === 1) paqueteRow.idCliente = paquete.IdCliente ? `B${paquete.IdCliente}` : '';
            paqueteRow.cliente = paquete.Cliente ? paquete.Cliente : '';
            paqueteRow.clasificacion = paquete.Clasificacion ? getClasificacion(paquete.Clasificacion) : '';
            paqueteRow.identificacion = paquete.Identificacion ? paquete.Identificacion : 'S.P.';
            paqueteRow.plataforma = itemName === translate("global.packages") ? (paquete.Plataforma ? paquete.Plataforma : '-') : (paquete.Direccion ? paquete.Direccion : '-');
            
            rows.push(paqueteRow);
            return false;
        });

        let fileName = "";
        let reportName = "";
        if (itemName === translate("global.reservations")) {
            fileName = translate("global.report")+"Boxit";
            reportName = translate("global.packagesToReserve");
        }
        else if (itemName === translate("global.deliveries")) {
            fileName = translate("global.guide")+"Boxit";
            reportName = translate("global.packagesToDeliver");
        }
        else {
            fileName = translate("global.report")+"Boxit";
            reportName = translate("global.packageSearch");
        }
        exportToPDF(reportName, fileName, columns, rows); 
    }
    
    useEffect(() => {
        if (actualPage >= pages) setActualPage(pages);
        if (actualPage === 0) setActualPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])
    
    useEffect(() => {
        if (Math.ceil(itemsFound.length / itemsShowing) !== pages) setPages(Math.ceil(itemsFound.length / itemsShowing));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsFound, itemsShowing]);

    return(
        <>
            <Results
                ItemComponent={ ItemComponent }
                itemName={ itemName }
                showBox={ showBox }
                setSelectedItem={ setSelectedItem }
                itemsFound={ itemsFound }
                actualPage={ actualPage }
                itemsShowing={ itemsShowing }
                view={view} />

            { itemsFound?.length > 0 &&
                <ItemsPagination
                    actualPage={ actualPage }
                    setActualPage={ setActualPage }
                    pages={ pages }
                    itemsFound={ itemsFound }
                    exportData={exportData}
                    itemName={itemName}
                    handleCancelBusqueda={handleCancelBusqueda}/>
            }
        </>
    );
}

export default ShowItems;