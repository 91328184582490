import Spinner from '../../../../components/Spinner';
import Tooltip from '../../../../components/Tooltip';
import { useContext } from 'react';
import AuthContext from '../../../../contexts/auth';
import {translate} from "../../../../utils/Common";

// paquetes retirados en el año actual
const PackagesPickUpLastYear = ({ cantPackagesPickUpLastYear }) => {
    const userContext = useContext(AuthContext);

    return ((cantPackagesPickUpLastYear || cantPackagesPickUpLastYear === 0) ?
        ( cantPackagesPickUpLastYear === 0 ?
            <p className="p-0 m-0">{translate("dashboard.home.packages_pick_up_lastyear")}</p>
            :
            <>
                <h4 className="p-0 m-0"><span className="text-bold">{ cantPackagesPickUpLastYear }</span></h4>
                <div className="d-flex flex-inline">
                    <p className="p-0 m-0">{translate("dashboard.home.packages_pick_up_lastyear.packages")} { userContext.user?.IdTipoUsuario === 1 ? translate("dashboard.home.packages_pick_up_lastyear.packages.delivered") : translate("dashboard.home.packages_pick_up_lastyear.packages.retired") } {translate("dashboard.home.packages_pick_up_lastyear.packages.in_this_year")}</p>
                    <Tooltip  className="mx-1"
                    info={ 
                        `${translate("dashboard.home.packages_pick_up_lastyear.info.indicator")} ${ userContext.user?.IdTipoUsuario === 1 ? translate("dashboard.home.packages_pick_up_lastyear.info.delivered") : translate("dashboard.home.packages_pick_up_lastyear.info.retired") } ${translate("dashboard.home.packages_pick_up_lastyear.info.in_this_year")}`
                    } />
                </div>
            </>
        )
        :
        <Spinner type={ 1 }/>
    );  
}

export default PackagesPickUpLastYear;