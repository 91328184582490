import React, { useState, useEffect, useContext } from 'react';
// import './index.css';
import AuthContext from '../../../../contexts/auth';
import { getClientDomain } from '../../../../utils/Common';

// components
import StatusPlatforms from '../AdminStats/StatusPlatforms';
import MostUsedPlatform from '../AdminStats/MostUsedPlatform';
import PackagesPickUpLastYear from '../AdminStats/PackagesPickUpLastYear';
import PackagesExpired from './PackagesExpired';
import PackagesWaitingDelivery from './PackagesWaitingDelivery';
import PackagesInPlatforms from './PackagesInPlatforms';
import GraphPackagesPickUpByPlatform from './GraphPackagesPickUpByPlatform';
import GraphPackagesInPlatforms from './GraphPackagesInPlatforms';

const UserHome = () => {
    const UserContext = useContext(AuthContext);
    
    const [statusPlatforms, setStatusPlatforms] = useState(null);
    const [cantPackagesInLocker, setCantPackagesInLocker] = useState(null);
    const [cantPackagesExpired, setCantPackagesExpired] = useState(null);
    const [cantPackagesDelivery, setCantPackagesDelivery] = useState(null);
    const [cantPackagesPickUp, setCantPackagesPickUp] = useState(null);
    const [cantPackagesPickUpLastYear, setCantPackagesPickUpLastYear] = useState(null);

    useEffect(() => {
        const controller = new AbortController();
        const getDashboardInfo = async () => {
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/expired/user/${UserContext.user?.Ruc}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    signal: controller.signal
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload.length > 0) {
                            setCantPackagesExpired(result.payload[0].CantPaquetesVencidos);
                        }
                        else {
                            setCantPackagesExpired(0);
                        }
                    }
                    else {
                        setCantPackagesExpired(0);
                    }
                }
            } catch (error) {
                console.log('error:',error);
            }
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/awaitingDelivery/user/${UserContext.user?.Ruc}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload[0]) {
                            setCantPackagesDelivery(result.payload[0].CantPaquetesEntrega);
                        }
                        else {
                            setCantPackagesDelivery(0);
                        }
                    }
                    else {
                        setCantPackagesDelivery(0);
                    }
                }
            } catch (error) {
                console.log('error:',error);
            }
            try {
                let dataToSend = {};
                dataToSend.mes = new Date().getMonth() + 1;
                dataToSend.ano = new Date().getFullYear();

                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/pickUpXMonth/user/${UserContext.user?.Ruc}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    body: JSON.stringify(dataToSend)
                });

                const result = await response.json();
                if (result) {
                    if (result.code === 200) {
                        if (result.payload) {
                            if (result.payload.length > 0) {
                                setCantPackagesPickUp(result.payload);
                            }
                            else {
                                setCantPackagesPickUp([]);
                            }
                        }
                    }
                    else {
                        setCantPackagesPickUp([]);
                    }
                }
            } catch (error) {
                console.log('error:',error);
            }

            if (UserContext.user?.IdTipoUsuario !== 1) {
                try {
                    let dataToSend = {};
                    dataToSend.ano = new Date().getFullYear();
                    const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/pickUpLastYear/user/${UserContext.user?.Ruc}`, {
                        method: 'POST',
                        accept: 'application/json',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${sessionStorage.getItem('token')}`
                        },
                        body: JSON.stringify(dataToSend)
                    });

                    const result = await response.json();
                    if (result) {
                        if (result.payload) {
                            let cantPaquetes = 0;

                            if (result.payload?.length > 0) {
                                result.payload?.map(plataforma => cantPaquetes += plataforma.value);
                                setCantPackagesPickUpLastYear(cantPaquetes)
                            }
                            else setCantPackagesPickUpLastYear(0);
                        }
                        else setCantPackagesPickUpLastYear(0);
                    }
                } catch (error) {
                    console.log('error:',error);
                }
            }
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/packagesInLockers/user/${UserContext.user?.Ruc}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    signal: controller.signal
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload?.length > 0) {
                            setCantPackagesInLocker(result.payload);
                        }
                        else {
                            setCantPackagesInLocker([]);
                        }
                    }
                }
            } catch (error) {
                console.log('error:',error);
            }
            if (UserContext.user?.IdTipoUsuario !== 1) {
                try {
                    const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/status/client/${UserContext.user?.IdCliente}`, {
                        method: 'GET',
                        accept: 'application/json',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${sessionStorage.getItem('token')}`
                        },
                        signal: controller.signal
                    });

                    const result = await response.json();
                    if (result) {
                        if (result.payload) {
                            if (result.payload.length > 0) {
                                setStatusPlatforms(result.payload);
                            }
                            else {
                                setStatusPlatforms([]);
                            }
                        }
                    }
                } catch (error) {
                    console.log('error:',error);
                }
            }
        }

        getDashboardInfo();
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return  <>
                <div className="row mx-2 d-flex justify-content-between text-center">
                    <div className="col-12 col-md-6 p-2">
                        <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <GraphPackagesInPlatforms   cantPackagesInLockercantPackagesInLocker={ cantPackagesInLocker }/>
                        </div>
                    </div>  

                    <div className="col-12 col-md-3 p-0">
                        <div className="row card-info-vertical p-0 m-0 ">
                            <div className="col-12 col-md-12 p-2">
                                <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                    <PackagesExpired cantPackagesExpired={ cantPackagesExpired }/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-3 p-0">
                        <div className="row card-info-vertical p-0 m-0 ">
                            <div className="col-12 col-md-12 p-2">
                                <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                    <PackagesWaitingDelivery cantPackagesDelivery={ cantPackagesDelivery } />  
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>

                <div className={ "row mx-2 d-flex justify-content-between text-center " + (UserContext.user?.IdTipoUsuario === 1 && 'mb-3') }>
                    <div className={ "col-12 p-2 " + (UserContext.user?.IdTipoUsuario === 1 ? 'col-md-8' : 'col-md-6') }>
                        <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <GraphPackagesPickUpByPlatform cantPackagesPickUp={ cantPackagesPickUp } />
                        </div>
                    </div>

                    { UserContext.user?.IdTipoUsuario !== 1 ?
                        <div className="col-12 col-md-6 p-2">
                            <div className="row card-info p-0 m-0 pb-2">
                                <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                    <MostUsedPlatform cantPackagesPickUp={ cantPackagesPickUp }/> 
                                </div>
                            </div>
                            
                            <div className="row card-info p-0 m-0 pt-2">
                                <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                    <PackagesPickUpLastYear cantPackagesPickUpLastYear={ cantPackagesPickUpLastYear } />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-12 col-md-4 p-2">
                            <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <PackagesInPlatforms cantPackagesInLocker={ cantPackagesInLocker } />
                            </div>
                        </div>
                    }
                </div>
                
                {  UserContext.user?.IdTipoUsuario !== 1 &&
                    <div className="row mx-2 mb-2 d-flex justify-content-between">
                        <div className="col-12 col-md-7 p-2">
                            <div className="dashboardCard m-0 px-0 w-100 h-100">
                                <StatusPlatforms statusPlatforms={ statusPlatforms } />
                            </div>
                        </div>

                        <div className="col-12 col-md-5 p-2">
                            <div className="dashboardCard m-0 px-0 w-100 h-100">
                                <PackagesInPlatforms cantPackagesInLocker={ cantPackagesInLocker } />
                            </div>
                        </div>
                    </div>
                }
            </>
}

export default UserHome;