import { useContext, useEffect, useState, useMemo } from "react";
import Select from 'react-select';
import { getClientDomain, getToken, translate } from "../utils/Common";
import AuthContext from "../contexts/auth";
import debounce from 'lodash.debounce';
import Spinner from "./Spinner";

const ClientsSearchList = (props) => {
  const userContext = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  // Función para buscar clientes con base en el término de búsqueda
  const searchClients = async (term) => {
    setLoading(true);
    try {
      const response = await fetch(`${getClientDomain(2)}/dashboard/clients/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          buscarCliente: term.toUpperCase(),
          idUsuario: props?.idUsuarioSelected ?? userContext.user?.Ruc,
          idTipoCliente: props.idTipoCliente,
        }),
      });

      const result = await response.json();
      if (result.code === 200 && result.payload && Array.isArray(result.payload)) {
        const limitedResults = result.payload.slice(0, 100);

        const groupedClients = limitedResults.reduce((acc, item) => {
          const initial = item.NombreCliente.charAt(0).toUpperCase();
          if (!acc[initial]) {
            acc[initial] = [];
          }
          acc[initial].push({
            value: item.IdCliente,
            label: `${item.IdCliente} - ${item.NombreCliente}`,
          });
          return acc;
        }, {});

        const formattedOptions = Object.keys(groupedClients).map(initial => ({
          label: initial,
          options: groupedClients[initial],
        }));

        setClients(formattedOptions);
        setAllClients(formattedOptions);
      } else {
        setClients([]);
        setAllClients([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Maneja el cambio en el input de búsqueda
  const handleInputChange = (inputValue) => {
    // Usa lodash.debounce para limitar la cantidad de llamadas a la API
    const debouncedSearch = debounce((term) => searchClients(term), 300);
    setSearchTerm(inputValue);
    if (inputValue) {
      debouncedSearch(inputValue);
    } else {
      // Muestra todas las opciones cuando el input está vacío
      setClients(allClients);
    }
  };

  // Cargar los primeros 100 clientes al montar el componente
  useEffect(() => {
    // Solo realiza la carga inicial una vez
    const loadInitialClients = async () => {
      if (allClients.length === 0) {
        await searchClients('');
      }
    };
    loadInitialClients();

    props.setError('buscarCliente', {
      type: '',
      message: ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClients]);

  // Usa useMemo para memorizar los datos del Select y evitar renders innecesarios
  const selectOptions = useMemo(() => clients, [clients]);

  return (
    <div>
        <Select
          value={selectedOption}
          onInputChange={handleInputChange}
          options={selectOptions}
          isLoading={loading}
          placeholder={translate("global.search")}
          formatGroupLabel={({ label }) => <div>{label}</div>}
          name="buscarCliente"
          onChange={(e) => {
            props.handleSelectedClient(e?.value);
            setSelectedOption(e);
          }}
          required
        />
      {props.errors.buscarCliente && (
        <span className="text-danger font-xsmall">{props.errors.buscarCliente.message}</span>
      )}
    </div>
  );
};

export default ClientsSearchList;
