import { Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import { useState, useContext } from 'react';
import { getClDomain } from '../../utils/Common';
// provider
import ModalProvider from "../../contexts/modal/Provider";
// contexts
import AuthContext from '../../contexts/auth';
import SidebarContext from "../../contexts/sidebar";
// components
import Spinner from '../../components/Spinner';
import UserProfile from './UserProfile';
import Home from './Home';
import Clients from './Clients';
import Reservations from './Reservations';
import Faqs from './Faqs';
import Packages from './Packages';
import AwaitingDelivery from './AwaitingDelivery';
import Insights from './Insights';
import PickLocation from './PickLocation';
import Delivery from './Delivery';
import './index.css';
import General from './Platforms';
import ItemPlatform from "../../components/ShowItems/ItemPlatform"
import Support from './Platforms/Support';
import { Accounting } from './Accounting';
import Billing from './Billing';
import BillingAdmin from './Accounting/BillingAdmin';
import RegisterIncdent from './Incidents/Register';
import ListIncidents from './Incidents/List';
import UpdateIncident from './Incidents/UpdateIncident';
import { verifyAccessViewPermissions } from '../../config/permissions';
import DetailsClients from './Clients/DetailsClients';
import CreateClient from './Clients/CreateClient';
import LocatePlatforms from './Platforms/LocatePlatforms';
// import CreatePlatform from './Platforms/CreatePlatform'

const Dashboard = () => {
	const userContext = useContext(AuthContext);
	const sidebarContext = useContext(SidebarContext);
    let { url } = useRouteMatch();
	const history = useHistory();
	const [update, setUpdate] = useState(false);

	const updateSidebarInfo = () => setUpdate(!update);

	if (!sessionStorage.getItem('token') && userContext.loading) {
		return  <div className="loader center">
				<Spinner type={0} size={40}/>
				</div>
	}
	if (!userContext.loading && userContext.user === null) history.push('/');

	return (
	<>
    	<div 
            id='mainLayout'
            className={"main-layout" + (sidebarContext.sidebarOpen ? "-open" : "")} 
            style={ getClDomain() !== 'bli.myboxit.com' ? { top: '82px', height: 'calc(100% - 82px)' } : { top: '60px', height: 'calc(100% - 60px)' } }
            onClick={ () => (sidebarContext.isMobile && sidebarContext.sidebarOpen) ? sidebarContext.closeSidebar() : null } >
            <ModalProvider>
                <Switch>
                    <Route path={`${url}`} component={Home} exact />
                    <Route path={`${url}/packages/:page`} component={ Packages } /> 
                    <Route path={`${url}/lockers/reservations`} component={ Reservations } exact/> 
                    <Route path={`${url}/lockers/awaitingDelivery`} component={ AwaitingDelivery } exact/>
                    <Route path={`${url}/addDelivery/package=:idPackage&from=:from&locName=:locName&redirect=:redirect`} component={ PickLocation } exact/>
                    <Route path={`${url}/accounting`} component={ Accounting } exact/>
                    <Route path={`${url}/faqs`} component={ Faqs } exact/>
                    <Route path={`${url}/billing`} component={ Billing } exact/>
                    <Route path={`${url}/incidents/register`} component={ RegisterIncdent } exact/>
                    <Route path={`${url}/incidents`} component={ ListIncidents } exact/>
                    <Route path={`${url}/incidents/:id`} component={ UpdateIncident } exact/>
                  
                    <Route path={`${url}/adminBilling`} exact>
                    { verifyAccessViewPermissions(["view_screen_billing_admin"]) ?
                        <BillingAdmin />
                        :
                        <Redirect from={`${url}/adminBilling`} to={`${url}/`} />
                    }
                    </Route>

                    <Route path={`${url}/profile`} exact>
                        <UserProfile updateSidebarInfo={updateSidebarInfo} />
                    </Route>
                    
                    <Route path={`${url}/insights`} exact >
                    { userContext.user?.IdTipoUsuario === 6 ?
                        <Insights />
                        :
                        <Redirect from={`${url}/insights`} to={`${url}/`} />   
                    }
                    </Route>

                    <Route path={`${url}/deliveries`} exact>
                    { userContext.user?.IsAdmin ?
                        <Delivery />
                        :
                        <Redirect from={`${url}/deliveries`} to={`${url}/`} />
                    }
                    </Route>

                    <Route path={`${url}/platforms/support`} exact >
                    { verifyAccessViewPermissions(["view_screen_support_platforms"]) ?
                        <Support /> 
                        :
                        <Redirect from={`${url}/platforms/support`} to={`${url}/`} />   
                    }
                    </Route>

                    <Route path={`${url}/platforms/locate`} exact >
                    { verifyAccessViewPermissions(["view_locate_platform"]) ?
                        <LocatePlatforms /> 
                        :
                        <Redirect from={`${url}/platforms/locate`} to={`${url}/`} />   
                    }
                    </Route>
                    
                    <Route path={`${url}/platforms/general`} exact >
                    { verifyAccessViewPermissions(["view_screen_platforms"]) ?
                        <General ItemPlatform={ItemPlatform} title={"plataformas"} />
                        :
                        <Redirect from={`${url}/platforms/general`} to={`${url}/`} />   
                    }
                    </Route>

                    
                    <Route path={`${url}/clients`} exact >
                    { userContext.user?.IsAdmin ?
                        <Clients /> 
                        :
                        <Redirect from={`${url}/clients`} to={`${url}/`} />   
                    }
                    </Route>

                    <Route path={`${url}/clients/details/:clientId`} exact >
                    { userContext.user?.IsAdmin ?
                        <DetailsClients /> 
                        :
                        <Redirect from={`${url}/clients/details/:clientId`} to={`${url}/`} />   
                    }
                    </Route>

                    <Route path={`${url}/clients/create`} exact >
                    { userContext.user?.IsAdmin ?
                        <CreateClient /> 
                        :
                        <Redirect from={`${url}/clients/create`} to={`${url}/`} />   
                    }
                    </Route>

                
                    
                    {/*
                    <Route path={`${url}/platforms/createplatform`} exact >
                    { userContext.user?.IsAdmin ?
                        <CreatePlatform />
                        :
                        <Redirect from={`${url}/platforms/createplatform`} to={`${url}/`} />   
                    }
                    </Route>

                         
                    <Route path={`${url}/config`} exact >
                    { userContext.user?.IsAdmin ?
                        <Config />
                        :
                        <Redirect from={`${url}/config`} to={`${url}/`} />
                    }
                    </Route>
                    
                    <Route path={`${url}/checkout`} component={ Checkout } exact/>
                    */}
                    
                    <Redirect from={`${url}/*`} to={`${url}/`} />
                </Switch>
            </ModalProvider>
        </div>
    </>
	);
};

export default Dashboard;
