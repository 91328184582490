import {
  formatter,
  getColorStatusClient,
  getFormatDate,
  getFormatDateInput,
  getIconStatusClients,
  getLabelTipoCliente,
  getNameGender,
  getNameRoleBli,
  getNameStatusClients,
  getNameTipoPlanCliente,
  translate,
} from "../../../utils/Common";

import {
  FaRegBuilding,
  FaTimesCircle,
  FaUser,
  FaUserFriends,
} from "react-icons/fa";
import { GiPlatform } from "react-icons/gi";
import { IoPersonCircle } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { MdDateRange, MdOutlineMailOutline, MdPhone } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import { FaChevronCircleDown } from "react-icons/fa";


import { useParams } from "react-router-dom";
import { useClient } from "../../../hooks/useClient";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import ModalContext from "../../../contexts/modal";

import PlatformSearchList from "../../../components/PlatformSearchList";

import "./index.css";
import RoleBliSelect from "../../../components/Selects/RoleBliSelect";
import TypePlansClientSelect from "../../../components/Selects/TypePlansClientSelect";

const DetailsClients = () => {
  const { clientId } = useParams();
  const { getDetailsClient, updateDetailsClient } = useClient();
  const [detailClient, setDetailClient] = useState(null);
  const [optionSelect, setOptionSelect] = useState("");
  const [platformSelect, setPlatformSelect] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const modalContext = useContext(ModalContext);
  // para saber qué boxit se seleccionó
  const [isBoxit, setIsBoxit] = useState(false);
  const handleIsBoxit = useCallback((value) => setIsBoxit(value), []);

  const personalInfoRef = useRef(null);
  const platformInfoRef = useRef(null);
  const { register, handleSubmit, errors, setValue, reset } = useForm();

  const handleGetDetailsClient = async (data) => {
    const abortController = new AbortController();
    const response = await getDetailsClient(abortController.signal, data);
    setDetailClient(response);
  };

  const handleUpdateClient = async (data) => {
    const abortController = new AbortController();
    const response = await updateDetailsClient(abortController.signal, {
      clientId: clientId,
      typeUpdate: optionSelect,
      plataforma: detailClient?.IdPlataforma,
      ...data,
    });
    

    if (response) {
      if (response.code === 200) {
        modalContext.confirm(
          translate("global.alert.ready"),
          <span>{translate("dashboard.updateClient.success_message")}</span>,
          [
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.OK")}
            </button>,
          ],
          true
        );
      } else {
        modalContext.confirm(
          translate("global.alert.attention"),
          <span>{translate("dashboard.platforms.create.alert.error")}</span>,
          [
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.OK")}
            </button>,
          ],
          true
        );
      }
    }

    handleGetDetailsClient({
      clientId: clientId,
    });

    
    setOptionSelect("");
  };

  useEffect(() => {
    if (clientId) {
      handleGetDetailsClient({
        clientId: clientId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -------------------------

  const handleResetForm = () => {
    setPlatformSelect(null);
    reset()
    setOptionSelect("");
  }
  const renderBtnsUpdate = ({isDisabled}) => (
    <div className="d-flex mt-3">
      <button className="btn btn-fucsia btn-sm" disabled={isDisabled} type="submit">
        <IoIosSave size={18} className="mr-1" /> {translate("global.save")}
      </button>
      <button
        className="btn btn-outline-fucsia btn-sm ml-2"
        onClick={() => handleResetForm() }
        type="button"
      >
        {translate("global.cancel")}
      </button>
    </div>
  );

  const handleEditOptionClick = (option) => {
    setOptionSelect(option);

    if (isMobile) {
      setIsMenuOpen(!isMenuOpen);
    }
  

    switch (option) {
      case "EDIT_INFO_CLIENT":
        if (personalInfoRef.current) {
          personalInfoRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

      case "EDIT_PLATFORM_CLIENT":
        if (platformInfoRef.current) {
          platformInfoRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;

      case "DESACTIVATE_CLIENT":
        modalContext.confirm(
          translate("global.alert.attention"),
          <span>
            {translate("dashboard.clients.desactivate.alert.message")}
          </span>,
          [
            <button
              key={1}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.cancel")}
            </button>,
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => {
                handleSubmitUpdate({
                  idEstatus: 2,
                  typeUpdate: "EDIT_STATUS_CLIENT"
                });
              }}
            >
              {translate("global.accept")}
            </button>,
          ],
          true
        );

        break;

      case "ACTIVATE_CLIENT":
        modalContext.confirm(
          translate("global.alert.attention"),
          <span>{translate("dashboard.clients.activate.alert.message")}</span>,
          [
            <button
              key={1}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.cancel")}
            </button>,
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => {
                handleSubmitUpdate({
                  idEstatus: 1,
                  typeUpdate: "EDIT_STATUS_CLIENT"
                });
              }}
            >
              {translate("global.accept")}
            </button>,
          ],
          true
        );
        break;

      default:
        break;
    }
  };

  const handleSubmitUpdate = (data) => {
    handleUpdateClient(data);
  };

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
      const handleResize = () => window.innerWidth < 1040 ? setIsMobile(true) : setIsMobile(false);

      window.addEventListener('resize', handleResize);

      if (window.innerWidth < 1040) setIsMobile(true);
      
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
     {/* Header */}
     <div
        style={{
          position: "sticky",
          top: "0",
          backdropFilter: "blur(8px)",
          zIndex: 15,
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          padding: "15px 25px 0",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="text-left d-flex" style={{ alignContent: "center" }}>
            <a href="/dashboard/clients">
              <h5 className="link-hover-effect">
                <FaUserFriends size={18} className="mr-2" />
                {translate("global.clients")}
              </h5>
            </a>

          {
            detailClient && (
              <span className="ml-1 mt-1">
              {" "}
              {">"}{" "}
              {detailClient?.Nombre +
                " " +
                detailClient?.Apellidos +
                " (" + (detailClient?.IdTipoCliente === 1 ? "B" : "")+
                detailClient?.IdCliente +
                ")"}
            </span>
            )
          }
          </div>
        </div>
      </div>

      { detailClient ? (<div
      style={{
        padding: " 0 15px ",
        marginBottom:"50px",
        background: "#fcfcfc",
      }}
      className="h-100 w-100"
    >

      <div className="box-card-general mt-3">
        <div className="details-section-general">
          {/* --- Options Select ---*/}
          <div className="details-section-option">
            <div className="details-section-option-item active">
              {translate("global.generalData")}
            </div>
            
           

            {isMobile && (
    <div
      id="details-options-viewactions"
      className="details-section-option-item"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      <div>
        <span>{translate("global.chooseOption")}</span>
      </div>
      <FaChevronCircleDown size={15} />
    </div>
  )}

  {/* Renderiza las listas de opciones siempre, pero oculta "Elegir opción" en otros dispositivos */}
  {(!isMobile || isMenuOpen) && (
    <>
      <div
        className="details-section-option-item"
        onClick={() => handleEditOptionClick("EDIT_INFO_CLIENT")}
      >
        <CiEdit size={20} /> {translate("global.editData")}
      </div>

      <div
        className="details-section-option-item"
        onClick={() => handleEditOptionClick("EDIT_PLATFORM_CLIENT")}
      >
        <GiPlatform size={20} />
        {translate("global.editPlatform")}
      </div>

      {(detailClient?.IdEstatus === 2 || detailClient?.IdEstatus === 3) && (
        <div
          className="details-section-option-item"
          onClick={() => handleEditOptionClick("ACTIVATE_CLIENT")}
        >
          <FaCircleCheck size={16} />
          {translate("global.activateClient")}
        </div>
      )}
      
      {(detailClient?.IdEstatus === 1 || detailClient?.IdEstatus === 3) && (
        <div
          className="details-section-option-item"
          onClick={() => handleEditOptionClick("DESACTIVATE_CLIENT")}
        >
          <FaTimesCircle size={16} />
          {translate("global.desactivateClient")}
        </div>
      )}

  
    </>
  )}

          </div>

          <div className="details-section-information">
            <form onSubmit={handleSubmit(handleSubmitUpdate)}>
              {/* Info general Cliente - View */}
              <div className="details-section-info-group">

                  <div className="box-card-general-dash w-100 position-relative">
                    <div className="d-flex">
                      <div className="details-profile-icon mr-3">
                        <IoPersonCircle size={50} />
                      </div>

                      <div>
                        <h6 className="text-capitalize font-bold">
                          {detailClient?.Nombre +
                            " " +
                            detailClient?.Apellidos +
                " (" + (detailClient?.IdTipoCliente === 1 ? "B" : "")+
                detailClient?.IdCliente +
                            ")"}
                        </h6>

                        {detailClient?.Usuario ? (
                          <p className="mb-0">
                            <FaUser className="mr-2" />
                            {detailClient?.Usuario ? (
                              <>
                                {translate("sidebar.user")}:{" "}
                                <strong>{detailClient?.Usuario} </strong>
                              </>
                            ) : (
                              ""
                            )}
                          </p>
                        ) : (
                          ""
                        )}

                        <div className="other-details-client mt-1 mb-2">
                          {detailClient?.IdTipoCliente ? (
                            <p className="mb-2 mt-2">
                              - {translate("global.type")}{" "}
                              {translate("global.client")}:{" "}
                              <strong className="ml-2">
                                {getLabelTipoCliente(
                                  detailClient?.IdTipoCliente
                                )}{" "}
                              </strong>
                            </p>
                          ) : (
                            ""
                          )}

                         
                          {detailClient?.IdTipoCliente === 7 && detailClient?.IdRoleBli ? (
                            <>
                             <div className="m-0 d-flex align-items-baseline" style={{gap:"5px"}}>

                              <div className="m-0">
                              - {translate("global.role")} BLI:{" "}
                            </div>

                            <div>
                              {
                              optionSelect === "EDIT_INFO_CLIENT" ? (
                                <RoleBliSelect register={register} itemDefault={detailClient?.IdRoleBli}/>
                              ):(
                                <strong>
                                {getNameRoleBli(detailClient?.IdRoleBli)}
                              </strong>
                              )
                            } 

                              </div>

                             </div>

                            
                            </>
                          
                          ) : (
                            ""
                          )}

                          {detailClient?.IdTipoPlan && detailClient?.IdTipoCliente !== 7 ? (
                            <div className="m-0 d-flex align-items-baseline" style={{gap:"5px"}}>
                              <div>
                              - {translate("global.type")}{" "}
                              {translate("global.plan")}:{" "}
                              </div>
                              
                              <div>
                              {
                              optionSelect === "EDIT_INFO_CLIENT" ? (
                                <TypePlansClientSelect register={register} itemDefault={detailClient?.IdTipoPlan}/>
                              ):(
                                <strong>
                                {getNameTipoPlanCliente(
                                  detailClient?.IdTipoPlan
                                )}
                              </strong>
                              )
                            } 

                              </div>

                            
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <small className=" mt-2 mb-0">
                          {detailClient?.FechaCreacion
                            ? translate("global.createdThe") +" "+
                              getFormatDate(detailClient?.FechaCreacion)
                            : ""}
                        </small>                      

                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "-15px",
                        right: "-10px",
                      }}
                    >
                      <div className={'badge-status '+getColorStatusClient(detailClient?.IdEstatus)}>
                        
                        {translate("global.status")}{":  "}
                        <span className="">
                          {getIconStatusClients(detailClient?.IdEstatus)}
                        </span>
                        <span className="text-uppercase font-weight-bold">
                          {" "}
                          {getNameStatusClients(detailClient?.IdEstatus)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="box-card-general-dash w-100" ref={platformInfoRef} >
                    <div className="d-flex flex-row align-content-center" style={{ gap: "15px", height:"fit-content" }}>
                      <div>
                        <img
                          src="/../img/locker.png"
                          alt="locker.png"
                          style={{ height: "75px" }}
                        />
                      </div>

                      <div>
                        <small>{translate("global.platform")}</small>

                        {optionSelect !== "EDIT_PLATFORM_CLIENT" ? (
                          <>
                            <h6 className="text-capitalize">
                              {detailClient?.Plataforma}
                            </h6>
                            <div className="badge-status">
                              <span className="mr-1">
                                <RiMoneyDollarCircleFill size={20} />
                              </span>
                              {translate("global.balance") + ": "}{" "}
                              <span className="text-uppercase font-weight-bold">
                                {detailClient?.SaldoCliente ? formatter.format(detailClient?.SaldoCliente) : "0.00"}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                             <PlatformSearchList
            register={register} 
            errors={errors}
            setPlatformSelect={setPlatformSelect}
            platfSelect={platformSelect} 
            valueId={"plataforma"} 
            readOnly={false}
            setValue={setValue}
            idPlatform={detailClient?.IdPlataforma}
            handleIsBoxit={handleIsBoxit}
            />

                            {optionSelect === "EDIT_PLATFORM_CLIENT" && renderBtnsUpdate({
                              isDisabled: platformSelect ? false : true,
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
              </div>

              {/* Informacion Personal - View */}
              <div className="details-section-info-group" ref={personalInfoRef}>
                <div className="box-card-general-dash w-100">
                  <h6>
                    <ImProfile /> {translate("dashboard.userprofile.personal.title")}
                  </h6>

                  {optionSelect === "EDIT_INFO_CLIENT" && renderBtnsUpdate({isDisabled:false})}

                  <div className="row">
                    <div className="details-info col-lg-4 col-md-6 col-sm-12">
                      <div className="details-info-label">
                        {translate("global.name")}
                      </div>
                      {optionSelect === "EDIT_INFO_CLIENT" ? (
                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          defaultValue={detailClient?.Nombre}
                          name="name"
                          ref={register}
                        />
                      ) : (
                        <div className="details-info-response">
                          {detailClient?.Nombre}
                        </div>
                      )}
                    </div>

                    <div className="details-info col-lg-4 col-md-6 col-sm-12">
                      <div className="details-info-label">
                        {translate("global.lastNames")}
                      </div>
                      {optionSelect === "EDIT_INFO_CLIENT" ? (
                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          defaultValue={detailClient?.Apellidos}
                          name="lastName"
                          ref={register}
                        />
                      ) : (
                        <div className="details-info-response">
                          {detailClient?.Apellidos}
                        </div>
                      )}
                    </div>

                    <div className="details-info col-lg-4 col-md-6 col-sm-12">
                      <div className="details-info-label">
                        <MdOutlineMailOutline className="mr-1" size={18} />
                        {translate("global.email")}
                      </div>
                      {optionSelect === "EDIT_INFO_CLIENT" ? (
                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          defaultValue={detailClient?.Email}
                          disabled
                        />
                      ) : (
                        <div className="details-info-response">
                          {detailClient?.Email}
                        </div>
                      )}
                    </div>

                    <div className="details-info col-lg-4 col-md-6 col-sm-12">
                      <div className="details-info-label">
                        <MdPhone className="mr-1" size={18} />
                        {translate("global.phone")}
                      </div>
                      {optionSelect === "EDIT_INFO_CLIENT" ? (
                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          defaultValue={detailClient?.Telefono}
                          name="phone"
                          ref={register}
                        />
                      ) : (
                        <div className="details-info-response">
                          {detailClient?.Telefono}
                        </div>
                      )}
                    </div>

                    <div className="details-info col-lg-4 col-md-6 col-sm-12">
                      <div className="details-info-label">
                        <MdDateRange className="mr-1" size={18} />{" "}
                        {translate(
                          "dashboard.userprofile.personal.birthdate_label"
                        )}
                      </div>
                      {optionSelect === "EDIT_INFO_CLIENT" ? (
                        <input
                          type="date"
                          className="form-control form-control-sm mt-2"
                          defaultValue={getFormatDateInput(
                            detailClient?.FechaNacimiento
                          )}
                          name="birthDate"
                          ref={register}
                        />
                      ) : (
                        <div className="details-info-response">
                          {detailClient?.FechaNacimiento
                            ? getFormatDate(detailClient?.FechaNacimiento)
                            : ""}
                        </div>
                      )}
                    </div>

                    <div className="details-info col-lg-4 col-md-6 col-sm-12">
                      <div className="details-info-label">
                        {translate("global.gender")}
                      </div>
                      {optionSelect === "EDIT_INFO_CLIENT" ? (
                        <select
                          className="form-control form-control-sm mt-2"
                          defaultValue={detailClient?.Sexo}
                          name="gender"
                          ref={register}
                        >
                          <option value="H">{translate("global.male")}</option>
                          <option value="M">{translate("global.female")}</option>
                        </select>
                      ) : (
                        <div className="details-info-response">
                          {detailClient?.Sexo
                            ? getNameGender(detailClient?.Sexo)
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Info BLI Empresa - View */}
              {
                detailClient?.IdTipoCliente === 7 && (
                  <div className="details-section-info-group">
                  <div className="box-card-general-dash w-100">
                    <h6>
                      <FaRegBuilding /> {translate("global.companyInfo")}
                    </h6>
  
  
                    <div className="row">
                      <div className="details-info col-lg-4 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.name")}
                        </div>
                        
                          <div className="details-info-response">
                            {detailClient?.CompanyLastName} {detailClient?.CompanyName}
                          </div>
                      </div>
  
                      <div className="details-info col-lg-4 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.hoursExpire")}
                        </div>
                        
                          <div className="details-info-response">
                            {detailClient?.HoursXExpire} h
                          </div>
                      </div>
  
                      <div className="details-info col-lg-4 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.dateCreated")}
                        </div>
                        
                          <div className="details-info-response">
                            {detailClient?.CompanyDateCreate ? getFormatDate(detailClient?.CompanyDateCreate) : ""}
                          </div>
                      </div>

                     
  
                    </div>
                  </div>
                </div>
                )
              }
          


            </form>
          </div>
        </div>
      </div>

    </div>) : (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="spinner-border text-fucsia" role="status" style={{color:"#424996"}}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    )}
    </>
   
  );
};

export default DetailsClients;
