import { useState } from "react";
import TablePaginationPlatforms from "../../../../components/TablePaginationPlatforms";
import { translate } from "../../../../utils/Common";

// cantidad de paquetes en cada locker
const PackagesInPlatforms = ({ cantPackagesInLocker }) => {
  const [headerRow] = useState(
    <tr>
      <th className="p-1 font-xsmall border-bottom border-top text-left">
        <div className="p-0 m-0 ml-2">
          {translate("dashboard.home.admin_stats.quantity")}
        </div>
      </th>
      <th className="p-1 font-xsmall border-bottom border-top text-center">
        {translate("dashboard.home.status_platforms.platform")}
      </th>
    </tr>
  );
  return (
    <TablePaginationPlatforms
      data={cantPackagesInLocker}
      headerRow={headerRow}
      tittle={translate("dashboard.home.packages_platforms.title")}
      filterStatus
      isActivePackage
    />
  );
};

export default PackagesInPlatforms;
