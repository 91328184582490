import { useCallback } from "react";
import { getClientDomain, getToken } from "../utils/Common";


export const usePackages = () => {
    
    const getPackages = useCallback(async (data) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/packages/find/openLocker` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(data),
           
        });
        
        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, [])

    const createPackage = useCallback(async (data, signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/packages/register/individual`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(data),
                signal 
        });
        
        const result = await response.json();
        
        return (result);
    }, [])

    return {getPackages, createPackage}
}